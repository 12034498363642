export const defaultTitle = 'Zキャリア'
// 外部Link
export const operatingCompanyPage = 'https://roxx.co.jp/'
export const privacyPolicyPage = 'https://roxx.co.jp/policy/privacy'
export const externalContent = 'https://roxxinc.notion.site/1a7d0eddb6724ecaafcb4ed792316438'
// WHY: Notionはやめてdocs/termsに利用規約を移行したためコメントアウト
// export const terms = 'https://roxxinc.notion.site/Z-1e3eff410a5449339be02f434a006282'
export const inquiryPage = 'https://forms.gle/YazpoDVQdtmfafP39'
export const companyDirect =
  'https://lp.company.direct.zcareer.com/?utm_source=zcareer&utm_medium=referrer&utm_campaign=01'
