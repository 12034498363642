import { Button, Flex } from '@chakra-ui/react'

import type { GTMClickItemName } from '@/modules/utils/gtm/types/common'

import { ChakraLink } from '../../ChakraLink'

interface Props {
  onClickLink: (e: React.MouseEvent<HTMLAnchorElement>, href: string) => void
  hrefTalentRegistration: string
  hrefTalentLogin: string
}

export const RegistrationAndLogin: React.FC<Props> = (props) => {
  const { onClickLink, hrefTalentRegistration, hrefTalentLogin } = props

  return (
    <Flex gap={1} m="0 auto" w="100%">
      <ChakraLink
        _hover={{ textDecoration: 'none' }}
        data-gtm-click-item={'menu_registration_button' as GTMClickItemName}
        href={hrefTalentRegistration}
        linkTo="talent"
        onClick={(e) => onClickLink(e, hrefTalentRegistration)}
        w="50%"
      >
        <Button
          _hover={{ bg: '_brand.neonGreen' }}
          bg="_brand.neonGreen"
          borderColor="_border.black"
          borderRadius="100px"
          fontWeight="bold"
          h="52px"
          p="12px 32px"
          size="md"
          variant="outline"
          w="100%"
        >
          会員登録（無料）
        </Button>
      </ChakraLink>
      <ChakraLink
        _hover={{ textDecoration: 'none' }}
        data-gtm-click-item={'menu_login_button' as GTMClickItemName}
        href={hrefTalentLogin}
        linkTo="talent"
        onClick={(e) => onClickLink(e, hrefTalentLogin)}
        w="50%"
      >
        <Button
          _hover={{ bg: '_surface.reversePrimary', color: '_text.invertPrimary' }}
          bg="_surface.primary"
          border="1px solid"
          borderColor="_border.black"
          borderRadius="100px"
          fontWeight="bold"
          h="52px"
          p="12px 32px"
          size="md"
          w="100%"
        >
          ログイン
        </Button>
      </ChakraLink>
    </Flex>
  )
}
