import { Flex, Text } from '@chakra-ui/react'

import { ChakraLink } from '@/modules/components/ChakraLink'
import type { GTMClickItemName } from '@/modules/utils/gtm/types/common'

interface Props {
  onClickLink: (e: React.MouseEvent<HTMLAnchorElement>, href: string) => void
  hrefGuideHome: string
}

export const Guide: React.FC<Props> = (props) => {
  const { onClickLink, hrefGuideHome } = props
  return (
    <ChakraLink
      _hover={{ textDecoration: 'none' }}
      data-gtm-click-item={'menu_scout_guide' as GTMClickItemName}
      href={hrefGuideHome}
      linkTo="guide"
      onClick={(e) => onClickLink(e, hrefGuideHome)}
    >
      <Flex alignItems="center" height="70px" justifyContent="space-between" p="12px 24px">
        <Text fontSize="16px" fontWeight="bold" lineHeight="1.5">
          就職・転職ガイド
        </Text>
      </Flex>
    </ChakraLink>
  )
}
