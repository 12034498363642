import { destroyCookie, parseCookies, setCookie } from 'nookies'

import { organicDomainList } from './organicDomainList'

/**
 * 流入パラメータCookieのキー名のenum
 */
export enum InflowCookieKeys {
  AD_PARAMS = 'zcd_ad_params',
  COOKIE_FROM_INFLOW = 'zc_from_inflow',
  UTM_SERVICE = 'zc_utm_service',
}
/**
 * Cookieのキー名のenum
 */
enum UtmServiceNames {
  ZCAREER_AGENT = 'zcareer-agent',
}
// CookieのAGE設定
const COOKIE_AGE = {
  DAYS_30: 30 * 24 * 60 * 60,
  GTM_COOKIE_AGE: 28 * 24 * 60 * 60,
  PATH: '/',
} as const

/**
 * URLから流入アクセスかを判定する
 * @returns 流入ならURLSearchParams、流入でなければundefined
 */
const getInflowQuery = (): URLSearchParams | undefined => {
  const query = new URL(window.location.href).searchParams
  // utmの必須パラメータがクエリ文字列に全部ある場合が広告流入
  const utmSource = query.get('utm_source')
  const utmCampaign = query.get('utm_campaign')
  const utmMedium = query.get('utm_medium')
  const utmService = query.get('utm_service')

  // utmパラメータを一つでも持っていたら流入と見なす
  if (utmSource || utmCampaign || utmMedium || utmService) {
    return query
  }
  return undefined
}

/**
 * リファラーからオーガニック流入アクセスかを判定する
 * @returns オーガニックだった場合はオーガニックutm_source名
 */
const getInflowReferer = (): string | undefined => {
  // リファラーがオーガニック流入ホストのリストにあったら流入元名を返す
  const referer = document.referrer
  const organicDomain = organicDomainList.find((domain) => referer.startsWith(domain.domain))
  if (organicDomain) {
    return organicDomain.name
  }
  return undefined
}

/**
 * サイト流入時の広告パラメーター関連のCookieをハンドリングする
 */
export const handleUtmCookie = (): void => {
  const query = getInflowQuery()
  const refererSourceName = getInflowReferer()

  // 広告または別プロダクト流入だった場合のハンドリング
  if (query) {
    // クエリパラメータを全てJSONとしてCookieに保存
    const queryJson: Record<string, string> = {}
    query.forEach((value, key) => {
      queryJson[key] = value
    })
    setCookie(null, InflowCookieKeys.AD_PARAMS, JSON.stringify(queryJson), {
      maxAge: COOKIE_AGE.DAYS_30,
      path: '/',
    })
    // AgentLPからの流入かどうかによって登録後の行き先遷移分岐用のCookieをハンドリングする
    const utmService = query.get('utm_service')
    // utm_serviceのクエリの値で判定
    if (utmService === UtmServiceNames.ZCAREER_AGENT) {
      setCookie(null, InflowCookieKeys.COOKIE_FROM_INFLOW, utmService, {
        maxAge: COOKIE_AGE.GTM_COOKIE_AGE,
        path: '/',
      })
    } else {
      destroyCookie(null, InflowCookieKeys.COOKIE_FROM_INFLOW, { path: '/' })
    }
  } else if (refererSourceName) {
    // 次に、オーガニック流入だった場合のハンドリング
    // GA4と値を揃えたUTMパラメータを保存する
    const utmJson: Record<string, string> = {
      utm_source: refererSourceName,
      utm_campaign: '(organic)',
      utm_medium: 'organic',
    }
    setCookie(null, InflowCookieKeys.AD_PARAMS, JSON.stringify(utmJson), {
      maxAge: COOKIE_AGE.DAYS_30,
      path: '/',
    })
  }
}
/**
 * handleUtmCookieで保存したAD_PARAMSのcookieをJSONとして取り出す
 */
export const getUtmCookieJson = (): Record<string, string> | null => {
  try {
    const cookies = parseCookies()
    const adParamsCookie = cookies[InflowCookieKeys.AD_PARAMS]
    if (!adParamsCookie) return null

    return JSON.parse(adParamsCookie)
  } catch (err) {
    // NOTE: パース失敗を捕捉する
    return null
  }
}
/**
 * AgentLPからの流入だったかのCookieを削除する
 */
export const deleteAgentLPCookies = () => {
  // 流入元Cookie削除
  destroyCookie(null, InflowCookieKeys.COOKIE_FROM_INFLOW, { path: '/' })
}

export const TEST_EXPORT = {
  getInflowQuery,
  COOKIE_AGE,
}
