import type { FC } from 'react'

interface LogoSmProps {
  width?: string
  height?: string
  className?: string
}

export const LogoSm: FC<LogoSmProps> = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 42 42"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_28776_75675)">
        <path
          d="M12.3331 6.25333L10.2734 13.2707L23.9217 11.8366L29.5409 4.79688L12.3331 6.25333Z"
          fill="black"
        />
        <path
          d="M29.5409 4.80067L3 38.0541L12.3814 36.4099L39 4L29.5409 4.80067Z"
          fill="url(#paint0_linear_28776_75675)"
        />
        <path
          d="M12.3828 36.4109L18.8102 28.5851L32.5895 26.4531L30.6644 33.1945L12.3828 36.4109Z"
          fill="black"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_28776_75675"
          x1="23.387"
          x2="18.5236"
          y1="34.4811"
          y2="6.7313"
        >
          <stop offset="0.1" stopColor="#50C864" />
          <stop offset="0.9" stopColor="#00B9FF" />
        </linearGradient>
        <clipPath id="clip0_28776_75675">
          <rect fill="white" height="34.0541" transform="translate(3 4)" width="36" />
        </clipPath>
      </defs>
    </svg>
  )
}
