export type ErrorMessageObject = {
  [key: number]: {
    statusMessage: string
    title: string
    description: string
  }
}

export const ErrorMassageObj: ErrorMessageObject = {
  400: {
    statusMessage: 'Not Found',
    title: 'お探しのページが見つかりません',
    description: `あなたがアクセスしようとしたページは削除されたかURLが変更されているため、見つけることができません。

もし当サイト内で無効なリンクを発見された場合、どのページのどのリンクが無効だったかをご報告頂けると幸いです。`,
  },
  403: {
    statusMessage: 'Forbidden',
    title: 'お探しのページにはアクセスができません',
    description: `あなたがアクセスしようとしたページには閲覧権限が必要です。
閲覧に必要な権限がユーザーに付与されているかを確認してください。`,
  },
  404: {
    statusMessage: 'Not Found',
    title: 'お探しのページが見つかりません',
    description: `あなたがアクセスしようとしたページは削除されたかURLが変更されているため、見つけることができません。

もし当サイト内で無効なリンクを発見された場合、どのページのどのリンクが無効だったかをご報告頂けると幸いです。`,
  },
  500: {
    statusMessage: 'Internal Server Error',
    title: 'ページが表示できません',
    description: `ご不便をおかけし申し訳ございません。サイトを正常にご覧いただけるよう、解決に取り組んでおります。

しばらく時間をおいてもご覧いただけない場合、お手数ですがお問い合わせください。`,
  },
  503: {
    statusMessage: 'Service Unavailable',
    title: 'ページが表示できません',
    description: `ご不便をおかけし申し訳ございません。サイトを正常にご覧いただけるよう、解決に取り組んでおります。

しばらく時間をおいてもご覧いただけない場合、お手数ですがお問い合わせください。`,
  },
}
