import { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/nextjs'
import { isAxiosError } from 'axios'
import { useAtomValue, useSetAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'

import { getGetMeQueryKey, getMe } from '@/api/__generated__/talent/auth/auth'
import type { MeTalent } from '@/api/__generated__/talent/zCareerAPIForTalent.schemas'
import { authMeAtom } from '@/globalState/auth'
import { getQueryClient } from '@/lib/queryClient'
import { sendClarityUserId } from '@/modules/utils/gtm/clarityEventActions'
import { setGAConfig } from '@/modules/utils/gtm/gaEventActions'

// firebaseへの通信を発生させないために、firebase非依存
export const useAuthGlobal = () => {
  const setMe = useSetAtom(authMeAtom)
  const queryClient = getQueryClient()

  const setAuthInfo = (data: MeTalent) => {
    setMe(data)
    // GA4イベント送信
    setGAConfig({ talent_id: data.id })
    // Sentryにユーザー情報を送信
    Sentry.setUser({
      id: data.id,
    })
    datadogRum.setUser({
      id: data.id.toString(),
    })
    sendClarityUserId()
  }

  const fetchMe = async () => {
    try {
      // WHY: データ取得と同時にキャッシュとJotaiも更新しつつTanStack Query移行時に互換性を保つためfetchQueryを使用
      const data = await queryClient.fetchQuery({
        queryKey: getGetMeQueryKey(),
        queryFn: () => getMe(),
        staleTime: 0, // キャッシュを更新するために0を設定
      })
      setAuthInfo(data)
      return {
        status: 200,
        data: data,
      }
    } catch (err: unknown) {
      queryClient.setQueryData(getGetMeQueryKey(), null)
      return {
        status: isAxiosError(err) ? (err.response?.status ?? 500) : 500,
        data: null,
      }
    }
  }

  return { setAuthInfo, fetchMe }
}

/**
 * 認証状態をチェック
 * SSRで取得したログイン情報があればそれを使う
 * SSRで取得したログイン情報がない場合はAPIで認証状態をチェックする
 *
 * @param initialMeValue SSRで取得したログイン情報があれば入れる
 */
export const useAuthCheck = (initialMeValue: MeTalent | null) => {
  useHydrateAtoms([[authMeAtom, initialMeValue]])
  const me = useAtomValue(authMeAtom)

  const { setAuthInfo } = useAuthGlobal()

  useEffect(() => {
    if (initialMeValue) {
      setAuthInfo(initialMeValue)
    }
  }, [initialMeValue])

  return { me }
}
