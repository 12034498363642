import { Box, Flex, Text } from '@chakra-ui/react'

import { ChakraLink } from '@/modules/components/ChakraLink'
import type { GTMClickItemName } from '@/modules/utils/gtm/types/common'

interface Props {
  onClickLink: (e: React.MouseEvent<HTMLAnchorElement>, href: string) => void
  hrefTalentJobKeepList: string
  keptJobsCount: number
}

export const KeepedJob: React.FC<Props> = (props) => {
  const { onClickLink, hrefTalentJobKeepList, keptJobsCount } = props
  return (
    <ChakraLink
      _hover={{ textDecoration: 'none' }}
      data-gtm-click-item={'menu_keep_link' as GTMClickItemName}
      href={hrefTalentJobKeepList}
      linkTo="talent"
      onClick={(e) => onClickLink(e, hrefTalentJobKeepList)}
    >
      <Flex alignItems="center" height="70px" justifyContent="space-between" p="12px 24px">
        <Text fontSize="16px" fontWeight="bold" lineHeight="1.5">
          キープした求人
        </Text>
        {keptJobsCount > 0 && (
          <Box
            bg="_element.like"
            borderRadius="10px"
            color="_text.invertPrimary"
            fontSize="10px"
            lineHeight="10px"
            marginRight="12px"
            p="3px 4px"
          >
            {keptJobsCount}
          </Box>
        )}
      </Flex>
    </ChakraLink>
  )
}
