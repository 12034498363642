import { WarningIcon } from '@chakra-ui/icons'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'

import { ChakraLink } from '@/modules/components/ChakraLink'
import type { GTMClickItemName } from '@/modules/utils/gtm/types/common'

interface Props {
  onClickLink: (e: React.MouseEvent<HTMLAnchorElement>, href: string) => void
  hrefTalentScout: string
  unreadScoutCount: number
  isLogin?: boolean
  isScoutReady?: boolean
}

export const Scout: React.FC<Props> = (props) => {
  const { onClickLink, hrefTalentScout, unreadScoutCount, isLogin, isScoutReady = false } = props
  const isShowUnreadCount = isLogin && unreadScoutCount > 0
  const isShowStep2Warning = isLogin && !isScoutReady
  return (
    <ChakraLink
      _hover={{ textDecoration: 'none' }}
      data-gtm-click-item={'menu_scout_link' as GTMClickItemName}
      href={hrefTalentScout}
      linkTo="talent"
      onClick={(e) => onClickLink(e, hrefTalentScout)}
    >
      <Flex alignItems="center" height="70px" justifyContent="space-between" p="12px 24px">
        <Text fontSize="16px" fontWeight="bold" lineHeight="1.5" opacity={isLogin ? 1 : 0.4}>
          スカウト
        </Text>
        {/* NOTICE: 現状は論理的にisShowUnreadCountとisShowStep2Warningどちらかになるデータ構造 */}
        {isShowUnreadCount && (
          <Box
            bg="_element.like"
            borderRadius="10px"
            color="_text.invertPrimary"
            fontSize="10px"
            lineHeight="10px"
            marginRight="12px"
            p="3px 4px"
          >
            {unreadScoutCount}
          </Box>
        )}
        {isShowStep2Warning && <Icon as={WarningIcon} boxSize="24px" color="brand.yellow" />}
        {!isLogin && (
          <Text
            color="_text.primary"
            fontSize="12px"
            fontWeight="400"
            lineHeight="16.8px"
            paddingRight="12px"
          >
            ログイン後ご利用できます
          </Text>
        )}
      </Flex>
    </ChakraLink>
  )
}
