// layout/AppLayout.tsx
import type { ReactElement } from 'react'
import * as React from 'react'
import { Box } from '@chakra-ui/react'
import { useAtomValue } from 'jotai'

import { addLineFriendUrl, appUrl, guideUrl } from '@/config'
import { authMeAtom } from '@/globalState/auth'
import { useGlobalAlerts } from '@/hooks/talent/useGlobalAlerts'
import { useGlobalBadges } from '@/hooks/talent/useGlobalBadges'
import styles from '@/layout/AppLayout.module.scss'
import Footer from '@/modules/components/Footer/Footer'
import Header from '@/modules/components/Header'
import { isLocal, isProd } from '@/modules/utils/env'

type Props = {
  children: ReactElement
  showAgentConnectAppeal?: boolean
}

const AppLayout: React.FC<Props> = ({ children, showAgentConnectAppeal = true }) => {
  const me = useAtomValue(authMeAtom)
  const { showLineFriendNotice } = useGlobalAlerts()
  const { keepCount, unreadScoutCount } = useGlobalBadges()

  return (
    <div>
      {!isProd && <div className={styles['under-construction']} />}
      <Header
        addLineFriendUrl={addLineFriendUrl}
        guideAppUrl={guideUrl}
        isLocal={isLocal}
        isLogin={!!me}
        isScoutReady={!!me?.ready_to_receive_scout_at}
        keepCount={keepCount ?? 0}
        mode="talent"
        showLineFriendNotice={showLineFriendNotice ?? false}
        talentAppUrl={appUrl}
        talentName={me?.first_name}
        unreadScoutCount={unreadScoutCount ?? 0}
      />
      <Box>{children}</Box>
      <Footer
        guideAppUrl={guideUrl}
        isLocal={isLocal}
        showAgentConnectAppeal={showAgentConnectAppeal}
        talentAppUrl={appUrl}
      />
    </div>
  )
}

export default AppLayout
