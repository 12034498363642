import pino from 'pino'

import { appEnv } from './env'

const serverSideLogger = pino({
  transport:
    appEnv === 'local'
      ? {
          target: 'pino-pretty',
          options: {
            colorize: true,
            levelFirst: true,
          },
        }
      : undefined,
})

export default serverSideLogger
