import { MODULE_URL } from '@/modules/const/url'

interface JudgePreventArgs {
  pathname: string
  basePath: string
  talentAppUrl?: string
  guideAppUrl?: string
  href: string
}

export const judgePrevent = (args: JudgePreventArgs) => {
  const { pathname, basePath, talentAppUrl, guideAppUrl, href } = args
  const result =
    href === pathname ||
    (href === talentAppUrl + pathname && basePath !== '/guide') ||
    // router.pathnameがMODULE_URL.ROOTの時はguideのTOPの為、判定でrouter.pathnameを省く
    (href
      === guideAppUrl + basePath + (pathname === MODULE_URL.ROOT ? MODULE_URL.ROOT : pathname) &&
      basePath === '/guide')

  return result
}
