export type OrganicDomain = {
  domain: string
  name: string
}

export const organicDomainList: OrganicDomain[] = [
  // テスト用にGTMデバッグのリダイレクトをオーガニック流入と見なす
  {
    domain: 'https://tagassistant.google.com',
    name: 'GtmTest',
  },
  // ２０２５年１月２０日時点 実績ある流入元
  {
    domain: 'https://duckduckgo.com',
    name: 'DuckDuckGo',
  },
  {
    domain: 'https://www.bing.com',
    name: 'Bing',
  },
  {
    domain: 'https://bing.com',
    name: 'Bing',
  },
  {
    domain: 'https://www.google.ca',
    name: 'Google',
  },
  {
    domain: 'https://www.google.co.id',
    name: 'Google',
  },
  {
    domain: 'https://www.google.co.jp',
    name: 'Google',
  },
  {
    domain: 'https://www.google.co.kr',
    name: 'Google',
  },
  {
    domain: 'https://www.google.com',
    name: 'Google',
  },
  {
    domain: 'https://www.google.com.au',
    name: 'Google',
  },
  {
    domain: 'https://www.google.com.hk',
    name: 'Google',
  },
  {
    domain: 'https://www.google.com.ni',
    name: 'Google',
  },
  {
    domain: 'https://www.google.com.tw',
    name: 'Google',
  },
  {
    domain: 'https://www.google.com.vn',
    name: 'Google',
  },
  {
    domain: 'https://search.yahoo.co.jp',
    name: 'Yahoo',
  },
  // 以降はgoogleが公開しているオーガニックhostリストをもとに作成
  // SEE: https://support.google.com/analytics/answer/2795821?hl=ja#zippy=%2C%E3%81%93%E3%81%AE%E8%A8%98%E4%BA%8B%E3%81%AE%E5%86%85%E5%AE%B9
  {
    domain: 'https://360.cn',
    name: '360.cn',
  },
  {
    domain: 'https://www.alice.com',
    name: 'Alice',
  },
  {
    domain: 'https://aliceadsl.fr',
    name: 'Alice',
  },
  {
    domain: 'https://www.alltheweb.com',
    name: 'Alltheweb',
  },
  {
    domain: 'https://www.altavista.com',
    name: 'Altavista',
  },
  {
    domain: 'https://www.aol.com',
    name: 'AOL',
  },
  {
    domain: 'https://www.ask.com',
    name: 'Ask',
  },
  {
    domain: 'https://search.aol.fr',
    name: 'Ask',
  },
  {
    domain: 'https://alicesuche.aol.de',
    name: 'Ask',
  },
  {
    domain: 'https://search.auone.jp',
    name: 'Auone',
  },
  {
    domain: 'https://isearch.avg.com',
    name: 'Avg',
  },
  {
    domain: 'https://search.babylon.com',
    name: 'Babylon',
  },
  {
    domain: 'https://www.baidu.com',
    name: '百度（バイドゥ）',
  },
  {
    domain: 'https://biglobe.ne.jp',
    name: 'Biglobe',
  },
  {
    domain: 'https://search.centrum.cz',
    name: 'Centrum.cz',
  },
  {
    domain: 'https://search.comcast.net',
    name: 'Comcast',
  },
  {
    domain: 'https://search.conduit.com',
    name: 'Conduit',
  },
  {
    domain: 'https://www.cnn.com/SEARCH',
    name: 'CNN',
  },
  {
    domain: 'https://www.daum.net',
    name: 'Daum',
  },
  {
    domain: 'https://www.ecosia.org',
    name: 'Ecosia',
  },
  {
    domain: 'https://www.ekolay.net',
    name: 'Ekolay',
  },
  {
    domain: 'https://www.eniro.se',
    name: 'Eniro',
  },
  {
    domain: 'https://www.globo.com/busca',
    name: 'Globo',
  },
  {
    domain: 'https://go.mail.ru',
    name: 'go.mail.ru',
  },
  {
    domain: 'https://goo.ne.jp',
    name: 'goo.ne',
  },
  {
    domain: 'https://www.haosou.com/s',
    name: 'haosou.com',
  },
  {
    domain: 'https://search.incredimail.com',
    name: 'Incredimail',
  },
  {
    domain: 'https://www.kvasir.no',
    name: 'Kvasir',
  },
  {
    domain: 'https://www.bing.com',
    name: 'Live',
  },
  {
    domain: 'https://www.lycos.com',
    name: 'Lycos',
  },
  {
    domain: 'https://search.lycos.de',
    name: 'Lycos',
  },
  {
    domain: 'https://www.mamma.com',
    name: 'Mamma',
  },
  {
    domain: 'https://www.msn.com',
    name: 'MSN',
  },
  {
    domain: 'https://money.msn.com',
    name: 'MSN',
  },
  {
    domain: 'https://local.msn.com',
    name: 'MSN',
  },
  {
    domain: 'https://www.mynet.com',
    name: 'Mynet',
  },
  {
    domain: 'https://najdi.si',
    name: 'Najdi',
  },
  {
    domain: 'https://www.naver.com',
    name: 'Naver',
  },
  {
    domain: 'https://search.netscape.com',
    name: 'Netscape',
  },
  {
    domain: 'https://nifty.com',
    name: 'nifty',
  },
  {
    domain: 'https://szukaj.onet.pl',
    name: 'ONET',
  },
  {
    domain: 'https://www.ozu.es',
    name: 'Ozu',
  },
  {
    domain: 'https://www.qwant.com',
    name: 'Qwant',
  },
  {
    domain: 'https://rakuten.co.jp',
    name: '楽天',
  },
  {
    domain: 'https://rambler.ru',
    name: 'Rambler',
  },
  {
    domain: 'https://search-results.com',
    name: 'Search-results',
  },
  {
    domain: 'https://search.smt.docomo.ne.jp',
    name: 'search.smt.docomo',
  },
  {
    domain: 'https://sesam.no',
    name: 'Sesam',
  },
  {
    domain: 'https://www.seznam.cz',
    name: 'Seznam',
  },
  {
    domain: 'https://www.so.com/s',
    name: 'So.com',
  },
  {
    domain: 'https://www.sogou.com',
    name: 'Sogou',
  },
  {
    domain: 'https://www.startsiden.no/sok',
    name: 'Startsiden',
  },
  {
    domain: 'https://www.szukacz.pl',
    name: 'Szukacz',
  },
  {
    domain: 'https://buscador.terra.com.br',
    name: 'Terra',
  },
  {
    domain: 'https://search.tut.by',
    name: 'Tut.by',
  },
  {
    domain: 'https://search.ukr.net',
    name: 'Ukr',
  },
  {
    domain: 'https://search.virgilio.it',
    name: 'Virgilio',
  },
  {
    domain: 'https://www.voila.fr',
    name: 'Voila',
  },
  {
    domain: 'https://www.wp.pl',
    name: 'Wirtualna Polska',
  },
  {
    domain: 'https://www.yahoo.com',
    name: 'Yahoo',
  },
  {
    domain: 'https://yahoo.cn',
    name: 'Yahoo',
  },
  {
    domain: 'https://m.yahoo.com',
    name: 'Yahoo',
  },
  {
    domain: 'https://www.yandex.com',
    name: 'Yandex',
  },
  {
    domain: 'https://yandex.ru',
    name: 'Yandex',
  },
  {
    domain: 'https://www.yam.com',
    name: 'Yam',
  },
]
