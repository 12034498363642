import { formatDate } from '@/modules/utils/datetimeFormatter'

export const getDeliveryAnnounceText = (scoutDeliveryHour: string | null) => {
  const now = new Date()
  // 今の時間を取得
  const nowHour = now.getHours()
  // 明日の日付を取得
  const tomorrow = new Date(now)
  tomorrow.setDate(now.getDate() + 1)

  // 今の時間とスカウト配信時間を比較し、スカウト配信時間と同じ時間以上であればtrueを返す
  const isShowScout = () => {
    const hour = scoutDeliveryHour !== null ? Number(scoutDeliveryHour) : 18
    return nowHour >= hour
  }

  // isShowScoutを使用して次回通知文面を作成
  return `次のスカウトは${formatDate(
    isShowScout() ? tomorrow.toDateString() : now.toDateString(),
    'M/d',
  )}の${scoutDeliveryHour || 18}:00頃を予定しています！楽しみにお待ちください`
}
