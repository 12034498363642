import { isServer, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Provider } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'
import { queryClientAtom } from 'jotai-tanstack-query'

import { isProd, isStg } from '@/modules/utils/env'

let browserQueryClient: QueryClient | undefined

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // SSRの場合、クライアントでの即時再取得を避けるため
        // staleTimeをデフォルトで0以上に設定します
        staleTime: 60 * 1000,
      },
    },
  })
}

export function getQueryClient() {
  if (isServer) {
    // サーバー: 常に新しいqueryClientを作成
    return makeQueryClient()
  } else {
    // ブラウザ: まだqueryClientが存在しない場合のみ作成
    if (!browserQueryClient) browserQueryClient = makeQueryClient()
    return browserQueryClient
  }
}

type QueryProvidersProps = {
  children: React.ReactNode
}

function HydrateAtoms({ children }: QueryProvidersProps) {
  const queryClient = getQueryClient()
  useHydrateAtoms([[queryClientAtom, queryClient]])
  return children
}

export const QueryProviders = ({ children }: QueryProvidersProps) => {
  // 注意: サスペンスする可能性のあるコードとの間にサスペンスバウンダリーがない場合、
  // queryClientの初期化にuseStateを使用することは避けてください
  const queryClient = getQueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <Provider>
        <HydrateAtoms>{children}</HydrateAtoms>
      </Provider>
      {!isProd && !isStg && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}
