import { memo, useCallback } from 'react'
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image as ChakraImage,
  Spinner,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'

import Close from '@/modules/assets/icon/Brand/Close.svg'
import LogoSm from '@/modules/assets/icon/Brand/LogoSm.svg'
import ScoutInMenu from '@/modules/assets/icon/Brand/ScoutInMenu.png'
import { ScoutNotice, ScoutNoticeType } from '@/modules/components/Notices/ScoutNotice/ScoutNotice'
import { useWindowSize } from '@/modules/hooks/useWindowSize'
import type { GTMClickItemName } from '@/modules/utils/gtm/types/common'

import { ChakraLink } from '../ChakraLink'
import { ChakraNextImage } from '../ChakraNextImage'

import { Account } from './MenuItems/Account/Account'
import { Guide } from './MenuItems/Guide/Guide'
import { InquiriedJob } from './MenuItems/InquiriedJob/InquiriedJob'
import { JobSearch } from './MenuItems/JobSearch/JobSearch'
import { KeepedJob } from './MenuItems/KeepedJob/KeepedJob'
import { Scout } from './MenuItems/Scout/Scout'
import { RegistrationAndLogin } from './RegistrationAndLogin/RegistrationAndLogin'
import { judgePrevent } from './RegistrationAndLogin/utils'

interface Props {
  mode: 'talent' | 'guide'
  isLogin: boolean | undefined
  isOpen: boolean
  onClose: () => void
  isLocal: boolean
  talentAppUrl?: string
  guideAppUrl?: string
  unreadScoutCount: number
  keptJobsCount: number
  talentName?: string
  isScoutReady?: boolean
}

// eslint-disable-next-line react/display-name
const Menu = memo((props: Props) => {
  const {
    mode: appMode,
    isLogin,
    isOpen,
    onClose,
    isLocal,
    talentAppUrl,
    guideAppUrl,
    unreadScoutCount,
    keptJobsCount,
    talentName,
    isScoutReady,
  } = props
  const { isPcView } = useWindowSize()
  const router = useRouter()

  // local開発環境でポートが変わる都合、リンクをpathではなくurlにしたい
  const constructHref = (href: string, linkTo: 'talent' | 'guide') => {
    const hostName = linkTo === 'talent' ? talentAppUrl : guideAppUrl
    return isLocal ? `${hostName}${href}` : href
  }

  // props: href, onClickの2つに同値を渡す都合、変数化している（並びは登場順）
  const hrefTalentLogin = constructHref('/login', 'talent')
  const hrefTalentRegistration = constructHref('/registration/agent', 'talent')
  const hrefTalentScout = constructHref('/scout', 'talent')
  const hrefTalentJobKeepList = constructHref('/job/keep-list', 'talent')
  // const hrefTalentHome = constructHref(MODULE_URL.ROOT, 'talent')
  const hrefTalentInquiryList = constructHref('/inquiry/list', 'talent')
  const hrefTalentJob = constructHref('/job', 'talent')
  const hrefGuideHome = constructHref('/guide', 'guide')
  const hrefTalentAccount = constructHref('/account', 'talent')

  const onClickLink = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
      const shouldPrevent = judgePrevent({
        pathname: router.pathname,
        basePath: router.basePath,
        talentAppUrl,
        guideAppUrl,
        href,
      })
      if (shouldPrevent) {
        // SPEC: 同一ページ遷移企図に対しては、ドロワーメニュークローズのみ行い、画面遷移はしない
        e.preventDefault()
        onClose()
      }
    },
    [router.pathname],
  ) // router.asPathだと、クエリ変化に巻き込まれるのでrouter.pathnameを依存値としてwatch

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size={isPcView ? 'sm' : 'full'}>
      <DrawerOverlay />
      <DrawerContent
        bg="white"
        height="100svh"
        maxH="100svh"
        sx={{
          overflowY: 'scroll',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        <DrawerHeader borderBottom="1px solid" borderBottomColor="border.primary">
          <Flex alignItems="center" justifyContent="space-between">
            <ChakraNextImage alt="完了" src={LogoSm.src} />
            <DrawerCloseButton
              _focusVisible={{ boxShadow: 'none' }}
              h="32px"
              position="inherit"
              right={0}
              top={0}
              w="85px"
            >
              <ChakraNextImage alt="完了" src={Close.src} />
            </DrawerCloseButton>
          </Flex>
        </DrawerHeader>

        {isLogin && !isScoutReady && (
          <Flex p="16px">
            <ScoutNotice
              clickNameSuffix="menu"
              firstName={talentName ?? ''}
              scoutNoticeType={ScoutNoticeType.NO_SCOUT_PROFILE}
            />
          </Flex>
        )}
        <DrawerBody p={6}>
          {appMode === 'talent' && isLogin === undefined ? (
            <Flex alignItems="center" bg="surface.primary" justifyContent="center" mt="40px">
              <Spinner />
            </Flex>
          ) : (
            <Flex flexDirection="column" gap={isLogin ? '0px' : '24px'}>
              {!isLogin && (
                <RegistrationAndLogin
                  hrefTalentLogin={hrefTalentLogin}
                  hrefTalentRegistration={hrefTalentRegistration}
                  onClickLink={onClickLink}
                />
              )}

              <Flex flexDirection="column">
                <Divider borderColor="_border.black" />
                <Scout
                  hrefTalentScout={hrefTalentScout}
                  isLogin={isLogin}
                  isScoutReady={isScoutReady}
                  onClickLink={onClickLink}
                  unreadScoutCount={unreadScoutCount}
                />
                <Divider borderColor="_border.black" />
                {isLogin && (
                  <InquiriedJob
                    hrefTalentInquiryList={hrefTalentInquiryList}
                    onClickLink={onClickLink}
                  />
                )}
                {isLogin && <Divider borderColor="_border.black" />}
                <KeepedJob
                  hrefTalentJobKeepList={hrefTalentJobKeepList}
                  keptJobsCount={keptJobsCount}
                  onClickLink={onClickLink}
                />
                <Divider borderColor="_border.black" />
                <JobSearch hrefTalentJob={hrefTalentJob} onClickLink={onClickLink} />
                <Divider borderColor="_border.black" />
                {/* TODO: 導線開放時に判定を!guideAppUrlにかえる */}
                <Guide hrefGuideHome={hrefGuideHome} onClickLink={onClickLink} />
                <Divider borderColor="_border.black" />
              </Flex>
              {isLogin && (
                <>
                  <Account hrefTalentAccount={hrefTalentAccount} onClickLink={onClickLink} />
                  <Divider borderColor="_border.black" />
                </>
              )}
              {/* TODO: 決まり次第差し替え */}
              {!isLogin && (
                <Box position="relative">
                  <ChakraImage alt="バナー" borderRadius="8px" src={ScoutInMenu.src} width="100%" />
                  <ChakraLink
                    _hover={{ textDecoration: 'none' }}
                    data-gtm-click-item={'menu_scout_banner' as GTMClickItemName}
                    href={hrefTalentRegistration}
                    linkTo="talent"
                    onClick={(e) => onClickLink(e, hrefTalentRegistration)}
                    p="8px"
                  >
                    <Button
                      _hover={{ bg: '_surface.primary', color: '_text.primary' }}
                      bg="transparent"
                      border="1.41px solid"
                      borderColor="_border.primary"
                      borderRadius="37.5px"
                      bottom="50px"
                      color="_text.invertPrimary"
                      fontSize="16px"
                      fontWeight="700"
                      height="64px"
                      left="0"
                      lineHeight="16px"
                      margin="0 auto"
                      position="absolute"
                      right="0"
                      width="334px"
                    >
                      スカウトを受け取る
                    </Button>
                  </ChakraLink>
                </Box>
              )}
            </Flex>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
})

export default Menu
