import { useMemo } from 'react'

import type { ScoutNoticeProps } from '@/modules/components/Notices/ScoutNotice/ScoutNotice'
import { DEFAULT_DELIVERY_HOUR } from '@/modules/const/defaultDeliveryHour'
import type { CharacterVariant } from '@/modules/utils/character'
import { getCharacterIconSource } from '@/modules/utils/character'
import { getDeliveryAnnounceText } from '@/modules/utils/deliveryAnnounce'

export enum ScoutNoticeType {
  NO_SCOUT_PROFILE = 'NO_SCOUT_PROFILE',
  RECEIVED_SCOUT = 'RECEIVED_SCOUT',
}
enum InnnerScoutNoticeType {
  NO_SCOUT_PROFILE = 'NO_SCOUT_PROFILE',
  RECEIVED_SCOUT = 'RECEIVED_SCOUT',
  CHANGE_DELIVERY_HOUR = 'CHANGE_DELIVERY_HOUR',
}

export const useScoutNotice = ({
  firstName,
  scoutNoticeType,
  unreadScoutsCount = 0,
  scoutDeliveryHour = DEFAULT_DELIVERY_HOUR,
}: ScoutNoticeProps) => {
  // 表示タイプ分岐
  const innnerScoutNoticeType: InnnerScoutNoticeType = useMemo(() => {
    switch (scoutNoticeType) {
      case ScoutNoticeType.NO_SCOUT_PROFILE:
        return InnnerScoutNoticeType.NO_SCOUT_PROFILE
      case ScoutNoticeType.RECEIVED_SCOUT:
        if (unreadScoutsCount > 0) return InnnerScoutNoticeType.RECEIVED_SCOUT
        return InnnerScoutNoticeType.CHANGE_DELIVERY_HOUR
      default:
        return InnnerScoutNoticeType.CHANGE_DELIVERY_HOUR
    }
  }, [scoutNoticeType, unreadScoutsCount])
  // 背景色
  const bgColor: { [K in InnnerScoutNoticeType]: string } = {
    [InnnerScoutNoticeType.NO_SCOUT_PROFILE]: 'brand.yellow-right',
    [InnnerScoutNoticeType.RECEIVED_SCOUT]: 'brand.blue-right',
    [InnnerScoutNoticeType.CHANGE_DELIVERY_HOUR]: 'brand.blue-right',
  }
  // アイコン種類
  const charactericonType: { [K in InnnerScoutNoticeType]: CharacterVariant } = {
    [InnnerScoutNoticeType.NO_SCOUT_PROFILE]: 'fullA',
    [InnnerScoutNoticeType.RECEIVED_SCOUT]: 'fullC',
    [InnnerScoutNoticeType.CHANGE_DELIVERY_HOUR]: 'fullB',
  }
  // メッセージ
  const messageText: { [K in InnnerScoutNoticeType]: string } = {
    [InnnerScoutNoticeType.NO_SCOUT_PROFILE]: `${firstName}さんにスカウトを届けるため、プロフィール登録をお願いします！ 登録は3分程度で終わります`,
    [InnnerScoutNoticeType.RECEIVED_SCOUT]: `${firstName}さんに興味をもった企業から、新しいスカウトが${unreadScoutsCount}件届いています！`,
    [InnnerScoutNoticeType.CHANGE_DELIVERY_HOUR]: getDeliveryAnnounceText(scoutDeliveryHour),
  }
  // ボタンのラベルと行き先
  const alertButtonValue = {
    [InnnerScoutNoticeType.NO_SCOUT_PROFILE]: {
      text: 'プロフィール登録',
      link: '/registration/scout',
    },
    [InnnerScoutNoticeType.RECEIVED_SCOUT]: {
      text: '新着スカウトを確認',
      link: '/scout/feedback',
    },
    [InnnerScoutNoticeType.CHANGE_DELIVERY_HOUR]: {
      text: '配信時間を変更',
      link: '/account/notification',
    },
  }

  return {
    bgColor: bgColor[innnerScoutNoticeType],
    characterIconSource: getCharacterIconSource(charactericonType[innnerScoutNoticeType]),
    messageText: messageText[innnerScoutNoticeType],
    alertButtonValue: alertButtonValue[innnerScoutNoticeType],
  }
}
