export const BRAND_COLOR = {
  RED: '#FF6774',
  RED_RIGHT: '#FFE1E3',
  RED_DEEP: '#A34B57',
  YELLOW: '#EFBC37',
  YELLOW_RIGHT: '#FCF2D7',
  YELLOW_DEEP: '#9A7E33',
  GREEN: '#4BD281',
  GREEN_RIGHT: '#DBF6E6',
  GREEN_DEEP: '#378B5F',
  BLUE: '#25A3DB',
  BLUE_RIGHT: '#D3EDF8',
  BLUE_DEEP: '#216F95',
  PURPLE: '#B399E9',
  PURPLE_RIGHT: '#F0EBFB',
  PURPLE_DEEP: '#76699D',
  NEON_GREEN: '#99FF00',
  NEON_RED: '#FF006A',
  NEON_BLUE: '#415AFF',
  EARTH_YELLOW: '#EFECE7',
} as const satisfies Record<string, `#${string}`>

/**
 * extendThemeに'gray.200'を指定しても反映されないので、カラーコードを直接指定する
 * 値は以下から
 * https://v2.chakra-ui.com/docs/styled-system/theme
 */
export const CHAKRA_COLOR = {
  BLACK: '#000',
  WHITE: '#FFF',
  GRAY: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
  },
  RED: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },
} as const
