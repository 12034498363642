import type { AxiosError, AxiosRequestConfig } from 'axios'
import axios from 'axios'

import serverSideLogger from '@/modules/utils/serverSideLogger'

import { appEnv } from '../config'

const logInfo = (obj: unknown) => {
  if (typeof window === 'undefined') {
    serverSideLogger.info({ msg: obj })
  }
}

const logError = (err: unknown) => {
  if (typeof window === 'undefined') {
    serverSideLogger.error({ err })
  }
}

const AXIOS_INSTANCE = axios.create({
  baseURL:
    typeof window === 'undefined'
      ? process.env.NEXT_PUBLIC_API_ROOT_FOR_SSR
      : process.env.NEXT_PUBLIC_API_ROOT,
  xsrfCookieName: `${appEnv.toUpperCase()}-XSRF-TOKEN`,
})

AXIOS_INSTANCE.interceptors.request.use((config) => {
  logInfo({
    url: config.url,
    method: config.method,
  })
  return config
})

AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    logInfo({ status: response.status })
    return response
  },
  (error) => {
    logError(error)
    return Promise.reject(error)
  },
)

export const axiosInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const source = axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    withCredentials: !config.url?.startsWith('/public'),
    cancelToken: source.token,
  }).then(({ data }) => data)

  // @ts-expect-error Promise doesn't have cancel property by default
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

export type ErrorType<Error> = AxiosError<Error>
export type BodyType<BodyData> = BodyData
