import React from 'react'
import type { LinkProps } from '@chakra-ui/react'
import { Link } from '@chakra-ui/react'

import type { GTMClickItemName } from '../utils/gtm/types/common'
import type { LinkToType } from '../utils/siteUrl'
import { linkSwitcher } from '../utils/siteUrl'

interface Props extends LinkProps {
  href: string // NextLinkはhrefにundefinedとnullが許容されていない
  'data-gtm-click-item'?: GTMClickItemName
  linkTo: LinkToType
}

/**
 * WHAT: このプロダクトのリンクは全てas={NextLink}で良さそうなので、ラップするコンポーネントを定義。
 * ChakraのLinkコンポーネントの全機能を使用可能。
 */
export const ChakraLinkWithoutAs: React.FC<Props> = ({ children, linkTo, ...props }) => {
  return (
    <Link {...props} href={linkSwitcher(props.href, linkTo)}>
      {children}
    </Link>
  )
}
