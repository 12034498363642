import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useQueryClient } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'

import {
  getFetchGlobalBadgesQueryKey,
  useFetchGlobalBadges,
} from '@/api/__generated__/talent/auth/auth'
import type { GlobalBadges } from '@/api/__generated__/talent/zCareerAPIForTalent.schemas'
import { authMeAtom } from '@/globalState/auth'

export interface KeptJobsCookie {
  jobId: string
  keepTime: string
}

export const useGlobalBadges = () => {
  const [keepCount, setKeepCount] = useState(0)
  const queryClient = useQueryClient()
  const meAtom = useAtomValue(authMeAtom)
  const [cookies] = useCookies(['keptJobs'])

  const { data, isLoading, refetch } = useFetchGlobalBadges<GlobalBadges, AxiosError>({
    query: { queryKey: getFetchGlobalBadgesQueryKey(), enabled: !!meAtom },
  })

  // Cookie取得値を普通にSSR向けに出すとHydrationエラーが発生するためuseEffectを介してしてCSR化して渡す
  useEffect(() => {
    if (meAtom) {
      // ログイン済みの場合はフェッチした値
      setKeepCount((data && data.keep_count) ?? 0)
    } else {
      // 未ログインの場合はCookieの値
      const keptJobs = cookies.keptJobs as KeptJobsCookie[]
      setKeepCount(keptJobs ? keptJobs.length : 0)
    }
  }, [meAtom, data?.keep_count, cookies.keptJobs])

  /**
   * キャッシュしているkeep_countの値をAPIフェッチを介さずに更新する
   */
  const localMutateKeepCount = (count: number) => {
    setKeepCount(count)
    queryClient.setQueryData<GlobalBadges>(getFetchGlobalBadgesQueryKey(), (old) => {
      const prev = old || { keep_count: 0, unread_scout_count: 0 }
      return { ...prev, keep_count: count }
    })
  }

  return {
    isLoadingGlobalBadges: isLoading,
    keepCount,
    unreadScoutCount: data?.unread_scout_count,
    mutateGlobalBadges: refetch,
    localMutateKeepCount,
  }
}
