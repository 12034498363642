import * as Sentry from '@sentry/nextjs'
import { isAxiosError } from 'axios'
import { toast } from 'sonner'

export const unhandledrejectionErrorHandler = (event: PromiseRejectionEvent) => {
  if (isAxiosError(event.reason)) {
    // responseがない場合はサーバに到達できなかった場合のエラー
    if (!event.reason.response) {
      toast.error('通信エラーが発生しました')
      console.error('API error without response', event.reason)
      Sentry.captureException(event.reason)
      return
    }
    // responseがある場合はサーバから帰ってきたエラー
    toast.error('予期せぬエラーが発生しました')
    console.error('API error with response', event.reason)
    Sentry.captureException(event.reason)
    return
  }
  // APIのエラーでなければ何もしない
  // clarityなどのエラーでも発動するため、暫定的に何もしない
  console.error('non API error', event.reason)
  Sentry.captureException(event.reason)
}
