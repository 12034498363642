import { Flex, Text } from '@chakra-ui/react'

import { ChakraLink } from '@/modules/components/ChakraLink'
import type { GTMClickItemName } from '@/modules/utils/gtm/types/common'

interface Props {
  onClickLink: (e: React.MouseEvent<HTMLAnchorElement>, href: string) => void
  hrefTalentJob: string
}

export const JobSearch: React.FC<Props> = (props) => {
  const { onClickLink, hrefTalentJob } = props
  return (
    <ChakraLink
      _hover={{ textDecoration: 'none' }}
      data-gtm-click-item={'menu_job_list_link' as GTMClickItemName}
      href={hrefTalentJob}
      linkTo="talent"
      onClick={(e) => onClickLink(e, hrefTalentJob)}
    >
      <Flex alignItems="center" height="70px" justifyContent="space-between" p="12px 24px">
        <Text fontSize="16px" fontWeight="bold" lineHeight="1.5">
          求人を探す
        </Text>
      </Flex>
    </ChakraLink>
  )
}
