import type { FC } from 'react'
import React from 'react'

interface HeartLikeFavoriteLoveIconProps {
  width?: string
  height?: string
  color?: string
  children?: React.ReactNode
}

export const HeartLikeFavoriteLoveIcon: FC<HeartLikeFavoriteLoveIconProps> = ({
  width,
  height,
  color = 'var(--chakra-colors-_element-linkPrimary)',
  children,
}) => {
  return (
    <svg
      fill="none"
      height={height || '32'}
      viewBox="0 0 28 28"
      width={width || '32'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0056 23.3658L5.09136 15.2914C0.246668 10.4467 7.36835 1.14495 14.0056 8.67035C20.6427 1.14495 27.7322 10.479 22.9198 15.2914L14.0056 23.3658Z"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      {children}
    </svg>
  )
}
