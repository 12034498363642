/**
 * GA4イベント送信のための関数をexportするファイル
 * 関数の使用箇所には下記のコメントを記載
 * // GA4イベント送信
 *
 * @packageDocumentation
 */
import type { ParsedUrlQuery } from 'querystring'

import { MODULE_URL } from '../../const/url'

import type { GTMModalViewName } from './types/common'
import type { GAConfigVariables, GAEventName, GAEventVariables } from './types/ga'
import type { GTMEventName } from './types/gtm'
import { sendGTM } from './gtmEventActions'

const gtmEventName: GTMEventName = 'ga_event'

export function sendGAEvent(ga_event_name: GAEventName, params?: GAEventVariables) {
  sendGTM({
    event: gtmEventName,
    ga_event_name: ga_event_name,
    ...params,
  })
}

type VideoRequiredParams = Required<
  Pick<GAEventVariables, 'video_duration' | 'video_url' | 'scout_id'>
>
type VideoOptionalParams = Pick<GAEventVariables, 'video_current_time' | 'video_percent'>

/**
 * 動画計測用の共通パラメータを生成
 */
function createVideoParams(
  params: VideoRequiredParams & Partial<VideoOptionalParams>,
): GAEventVariables {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { video_current_time, ...rest } = params

  if (typeof video_current_time === 'number') {
    return {
      ...rest,
      video_current_time: Math.floor(video_current_time),
      video_percent: Math.floor((video_current_time / params.video_duration) * 100),
    }
  }

  return rest
}

/**
 * video_startイベント送信
 */
export function sendGAVideoStart(
  params: VideoRequiredParams & Required<Pick<GAEventVariables, 'play_type'>>,
) {
  sendGAEvent('video_start', {
    ...createVideoParams(params),
    seek_from: 0,
    seek_to: 0,
  })
}

/**
 * video_stopイベント送信
 */
export function sendGAVideoStop(
  params: VideoRequiredParams & Required<Pick<GAEventVariables, 'video_current_time'>>,
) {
  sendGAEvent('video_stop', {
    ...createVideoParams(params),
    seek_from: 0,
    seek_to: 0,
  })
}

/**
 * video_progressイベント送信
 */
export function sendGAVideoProgress(
  params: VideoRequiredParams &
    Required<Pick<GAEventVariables, 'video_current_time' | 'video_percent'>>,
) {
  sendGAEvent('video_progress', {
    ...createVideoParams(params),
    seek_from: 0,
    seek_to: 0,
  })
}

/**
 * video_completeイベント送信
 */
export function sendGAVideoComplete(params: VideoRequiredParams) {
  sendGAEvent('video_complete', {
    ...createVideoParams(params),
    seek_from: 0,
    seek_to: 0,
  })
}

/**
 * video_seekイベント送信
 */
export function sendGAVideoSeek(
  params: VideoRequiredParams &
    Required<Pick<GAEventVariables, 'seek_from' | 'seek_to' | 'video_current_time'>>,
) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { seek_from, seek_to, ...baseParams } = params
  sendGAEvent('video_seek', {
    ...createVideoParams(baseParams),
    seek_from: Math.floor(seek_from),
    seek_to: Math.floor(seek_to),
  })
}

/**
 * video_rate_changeイベント送信
 */
export function sendGAVideoRateChange(
  params: VideoRequiredParams &
    Required<Pick<GAEventVariables, 'video_rate' | 'video_current_time'>>,
) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { video_rate, ...baseParams } = params
  sendGAEvent('video_rate_change', {
    ...createVideoParams(baseParams),
    video_rate,
    seek_from: 0,
    seek_to: 0,
  })
}

/**
 * HTMLVideoElementからパラメータを生成するユーティリティ関数
 */
export function createVideoParamsFromElement(
  videoElement: HTMLVideoElement,
  scout_id: string,
): VideoRequiredParams & Partial<VideoOptionalParams> {
  return {
    scout_id,
    video_duration: videoElement.duration,
    video_url: videoElement.src,
  }
}

/**
 * クエリ文字列→計測対象のみを抽出したJSON文字列
 * @param {ParsedUrlQuery} queryJson - Json化したクエリ文字列
 * @returns {string} - search_conditionのJSON文字列
 */
export function queryJsonToSearchCondition(queryJson?: ParsedUrlQuery): string {
  if (!queryJson) return '{}'
  const keysToKeep = ['workLocationPrefecture', 'jobCategory', 'particularCondition']
  const keysToChange = ['condition_1', 'condition_2', 'condition_3']
  const filteredJson: ParsedUrlQuery = {}

  // queryJsonのキーにkeysToKeepの値が含まれている場合
  if (keysToKeep.some((key) => Object.prototype.hasOwnProperty.call(queryJson, key))) {
    for (const key of keysToKeep) {
      if (Object.prototype.hasOwnProperty.call(queryJson, key)) {
        filteredJson[key] = queryJson[key]
      }
    }
  }
  // queryJsonのキーにkeysToChangeの値が含まれている場合
  if (keysToChange.some((key) => Object.prototype.hasOwnProperty.call(queryJson, key))) {
    for (const key of keysToChange) {
      const value = queryJson[key] as string[]
      if (value) {
        if (value[0].startsWith('jobCategory_')) {
          filteredJson.jobCategory = value[0].replace('jobCategory_', '')
        } else if (value[0].startsWith('workLocationPrefecture_')) {
          filteredJson.workLocationPrefecture = value[0].replace('workLocationPrefecture_', '')
        } else if (value[0].startsWith('particularCondition_')) {
          filteredJson.particularCondition = value[0].replace('particularCondition_', '')
        }
      }
    }
  }

  return JSON.stringify(filteredJson)
}
/**
 * searchイベント送信
 * @param {number} total - 合計件数
 * @param {string} [log_job_search_condition_id] - サーバーでログIDを出している場合のログID（オプション）
 */
export function sendGASearch(total: number, log_job_search_condition_id: string) {
  sendGAEvent('search', {
    total: total,
    log_job_search_condition_id: log_job_search_condition_id,
  })
}

/**
 * view_modal_stepイベント送信
 * @param {GTMModalViewName} [modal_step] - モーダルの中の画面名
 */
export function sendGaViewModalStep(modal_step: GTMModalViewName) {
  sendGAEvent('view_modal_step', {
    modal_step: modal_step,
  })
}
/**
 * click_more_jobイベント送信
 * @param {number} start_of_job_order_no - 何件目から
 * @param {number} end_of_job_order_no - 何件目まで
 */
export function sendGAClickMoreJob(start_of_job_order_no: number, end_of_job_order_no: number) {
  sendGAEvent('click_more_job', {
    start_of_job_order_no: start_of_job_order_no,
    end_of_job_order_no: end_of_job_order_no,
  })
}
/**
 * 会員登録ステップ2完了イベント送信
 * @param {string} source_product - ステップ2登録完了に至ったリファラー
 */
export function sendGaSingUpScoutInfoEvent(source_product: string) {
  sendGAEvent('sign_up_scout_info', {
    source_product: source_product,
  })
}

/**
 * 会員登録ステップ完了イベント送信
 * @param {string} source_product - ステップ1登録完了に至ったリファラー
 */
export function sendGASignUp(source_product: string) {
  sendGAEvent('sign_up', {
    source_product: source_product,
  })
}

/**
 * パラメータなしのGA4イベント送信
 * @param ga_event_name - 所定のイベント名
 */
export function sendGANoValueEvent(
  ga_event_name: 'login' | 'delete_account' | 'edit_profile' | 'edit_email',
) {
  sendGAEvent(ga_event_name)
}
/**
 * Googleタグのパラメータを更新
 * @param {GAConfigVariables} params - 更新するパラメータ
 */
export function setGAConfig(params: GAConfigVariables) {
  sendGTM({
    event: 'update_google_tag',
    ...params,
  })
}

/**
 * GTMで設定しているのと同じview_nameを取得する
 * SEE: https://tagmanager.google.com/#/container/accounts/6202904051/containers/171031778/workspaces/25/variables?orgId=Uk1PFwRoQmuNTsyz3lpinA
 * @param routerPath - routerのasPathで取得したパス
 */
export function getViewName(routerPath: string): string {
  const url = new URL(routerPath, 'http://dummy.com')
  const { pathname } = url
  switch (true) {
    case pathname === MODULE_URL.ROOT:
      return 'top'
    case pathname === '/login':
      return 'login'
    case pathname.startsWith('/job/detail') && pathname.endsWith('/inquiry'):
      return 'job_inquiry'
    case pathname.startsWith('/job/detail') && pathname.endsWith('/inquiry/confirm'):
      return 'job_inquiry_confirm'
    case pathname.startsWith('/job/detail'):
      return 'job_detail'
    case pathname.startsWith('/job/search'):
      return 'job_search'
    case pathname.startsWith('/job/keep-list'):
      return 'kept_job_list'
    case pathname.startsWith('/job'):
      return 'job_list'
    case pathname === '/account/profile':
      return 'account_profile'
    case pathname === '/account/desired-conditions':
      return 'account_desired_conditions'
    case pathname === '/account/contact':
      return 'account_contact'
    case pathname === '/account/auth-settings':
      return 'account_auth_settings'
    case pathname === '/account/notification':
      return 'account_notification'
    case pathname === '/account':
      return 'account_info'
    case pathname === '/inquiry/complete':
      return 'inquiry_complete'
    case pathname === '/inquiry/list':
      return 'inquiry_list'
    case pathname === '/registration/agent':
      return 'registration_agent'
    case pathname === '/registration/scout':
      return 'registration_scout'
    case pathname === '/scout/onboarding/start':
      return 'scout_onboarding_start'
    case pathname === '/scout/onboarding':
      return 'scout_onboarding'
    case pathname === '/scout/feedback':
      return 'scout_feedback'
    case pathname.startsWith('/scout') && pathname.endsWith('/inquiry/confirm'):
      return 'scout_inquiry_confirm'
    case pathname.startsWith('/scout') && pathname.endsWith('/inquiry'):
      return 'scout_inquiry'
    case /^\/scout\/[0-9A-Za-z]{26}\/movie$/.test(pathname):
      return 'scout_personal_video_detail'
    case pathname === '/scout':
      return 'scout_list'
    case /^\/scout(\/[0-9A-Za-z]{26})?$/.test(pathname):
      return 'scout_detail'
    case pathname.startsWith('/guide/category'):
      return 'guide_category'
    case pathname.startsWith('/guide/detail'):
      return 'guide_detail'
    case pathname === '/guide':
      return 'guide_top'
    default:
      return 'other'
  }
}

/**
 * STEP2入力促進バナーA | Bの表示イベント送信
 */
export function sendGAViewWhichBanner(displayedBanner: string) {
  const bannerName = `view_banner_${displayedBanner}` as GAEventName
  sendGAEvent(bannerName)
}

/**
 * スカウト確認ボタンのA | Bの表示イベント送信
 */
export function sendGAViewWhichScoutButton(displayedButton: string) {
  const buttonName = `view_scout_button_${displayedButton}` as GAEventName
  sendGAEvent(buttonName)
}
