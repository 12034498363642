/* eslint-disable no-console */
import { useCallback } from 'react'
import { useAtomValue } from 'jotai'

import { useUpdateLastAccess } from '@/api/__generated__/talent/auth/auth'
import { authMeAtom } from '@/globalState/auth'

export const useLastAccess = () => {
  const me = useAtomValue(authMeAtom)
  const { mutate } = useUpdateLastAccess()

  /**
   * 認証済みの場合最終アクセス履歴更新APIを実行する関数
   */
  const updateLastAccess = useCallback(() => {
    if (!me) return
    mutate()
  }, [me, mutate])

  return {
    updateLastAccess,
  }
}
