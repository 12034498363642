/**
 * Clarityイベント送信のための関数をexportするファイル
 * 関数の使用箇所には下記のコメントを記載
 * // Clarityイベント送信
 *
 * @packageDocumentation
 */
import type { ClarityFiredEventValueType, ClarityTagNameType } from './types/clarity'
import type { GTMEventName } from './types/gtm'
import { sendGTM } from './gtmEventActions'

const gtmEventName: GTMEventName = 'clarity_event'
const clarityTagName: ClarityTagNameType = 'fired_event'

export function sendClarityFiredEvent(value: ClarityFiredEventValueType) {
  sendGTM({
    event: gtmEventName,
    clarity_tag_name: clarityTagName,
    clarity_tag_value: value,
  })
}

export function sendClarityUserId() {
  sendGTM({
    event: 'clarity_send_user_id',
  })
}
