import type { FC } from 'react'
import { Box } from '@chakra-ui/react'

interface BadgeProps {
  count: number
}

export const Badge: FC<BadgeProps> = ({ count }) => {
  if (count <= 0) return null

  return (
    <Box
      alignItems="center"
      bg="_element.like"
      borderRadius="20px"
      display="flex"
      flexDirection="column"
      fontSize="10px"
      fontWeight="700"
      h="14px"
      justifyContent="center"
      minW="14px"
      p="2px 3px"
      position="absolute"
      right="-2px"
      textColor="_text.invertPrimary"
      top="0px"
    >
      {count > 99 ? '99+' : count}
    </Box>
  )
}
