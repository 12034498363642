/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * z-career API for talent
 * z-career API for talent web applications.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import type {
  DeleteRequest,
  GlobalAlerts,
  GlobalBadges,
  LineLoginUrlResponse,
  MeTalent,
  Registration201,
  RegistrationRequest,
} from '../zCareerAPIForTalent.schemas'
import { axiosInstance } from '../../../axios'
import type { ErrorType, BodyType } from '../../../axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary セッション開始用のログインエンドポイント、firebaseでの認証完了後、id tokenをAuthorizationヘッダに含める）
 */
export const login = (options?: SecondParameter<typeof axiosInstance>, signal?: AbortSignal) => {
  return axiosInstance<string>({ url: `/talent/auth/login`, method: 'POST', signal }, options)
}

export const getLoginMutationOptions = <
  TData = Awaited<ReturnType<typeof login>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['login']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof login>>, void> = () => {
    return login(requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError, void, TContext>
}

export type LoginMutationResult = NonNullable<Awaited<ReturnType<typeof login>>>

export type LoginMutationError = ErrorType<void>

/**
 * @summary セッション開始用のログインエンドポイント、firebaseでの認証完了後、id tokenをAuthorizationヘッダに含める）
 */
export const useLogin = <
  TData = Awaited<ReturnType<typeof login>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, void, TContext> => {
  const mutationOptions = getLoginMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary ログアウトAPI
 */
export const logout = (options?: SecondParameter<typeof axiosInstance>, signal?: AbortSignal) => {
  return axiosInstance<void>({ url: `/talent/auth/logout`, method: 'POST', signal }, options)
}

export const getLogoutMutationOptions = <
  TData = Awaited<ReturnType<typeof logout>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['logout']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof logout>>, void> = () => {
    return logout(requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError, void, TContext>
}

export type LogoutMutationResult = NonNullable<Awaited<ReturnType<typeof logout>>>

export type LogoutMutationError = ErrorType<void>

/**
 * @summary ログアウトAPI
 */
export const useLogout = <
  TData = Awaited<ReturnType<typeof logout>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, void, TContext> => {
  const mutationOptions = getLogoutMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary ユーザーが新規登録するAPI
 */
export const registration = (
  registrationRequest: BodyType<RegistrationRequest>,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<Registration201>(
    {
      url: `/talent/auth/registration`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: registrationRequest,
      signal,
    },
    options,
  )
}

export const getRegistrationMutationOptions = <
  TData = Awaited<ReturnType<typeof registration>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<RegistrationRequest> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['registration']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registration>>,
    { data: BodyType<RegistrationRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return registration(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<RegistrationRequest> },
    TContext
  >
}

export type RegistrationMutationResult = NonNullable<Awaited<ReturnType<typeof registration>>>
export type RegistrationMutationBody = BodyType<RegistrationRequest>
export type RegistrationMutationError = ErrorType<void>

/**
 * @summary ユーザーが新規登録するAPI
 */
export const useRegistration = <
  TData = Awaited<ReturnType<typeof registration>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<RegistrationRequest> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, { data: BodyType<RegistrationRequest> }, TContext> => {
  const mutationOptions = getRegistrationMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Talentユーザー情報取得API
 */
export const getMe = (options?: SecondParameter<typeof axiosInstance>, signal?: AbortSignal) => {
  return axiosInstance<MeTalent>({ url: `/talent/auth/me`, method: 'GET', signal }, options)
}

export const getGetMeQueryKey = () => {
  return [`/talent/auth/me`] as const
}

export const getGetMeQueryOptions = <
  TData = Awaited<ReturnType<typeof getMe>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMeQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMe>>> = ({ signal }) =>
    getMe(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMe>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetMeQueryResult = NonNullable<Awaited<ReturnType<typeof getMe>>>
export type GetMeQueryError = ErrorType<void>

/**
 * @summary Talentユーザー情報取得API
 */

export function useGetMe<
  TData = Awaited<ReturnType<typeof getMe>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMeQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Talentユーザー情報取得API
 */
export const prefetchGetMe = async <
  TData = Awaited<ReturnType<typeof getMe>>,
  TError = ErrorType<void>,
>(
  queryClient: QueryClient,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): Promise<QueryClient> => {
  const queryOptions = getGetMeQueryOptions(options)

  await queryClient.prefetchQuery(queryOptions)

  return queryClient
}

export const getGetMeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMe>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMeQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMe>>> = ({ signal }) =>
    getMe(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMe>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetMeSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getMe>>>
export type GetMeSuspenseQueryError = ErrorType<void>

/**
 * @summary Talentユーザー情報取得API
 */

export function useGetMeSuspense<
  TData = Awaited<ReturnType<typeof getMe>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMeSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 求職者アカウントを退会する
 * @summary Talentユーザー削除API
 */
export const authDeleteUser = (
  deleteRequest: BodyType<DeleteRequest>,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<void>(
    {
      url: `/talent/auth/delete`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: deleteRequest,
      signal,
    },
    options,
  )
}

export const getAuthDeleteUserMutationOptions = <
  TData = Awaited<ReturnType<typeof authDeleteUser>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<DeleteRequest> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['authDeleteUser']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authDeleteUser>>,
    { data: BodyType<DeleteRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return authDeleteUser(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<DeleteRequest> },
    TContext
  >
}

export type AuthDeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof authDeleteUser>>>
export type AuthDeleteUserMutationBody = BodyType<DeleteRequest>
export type AuthDeleteUserMutationError = ErrorType<void>

/**
 * @summary Talentユーザー削除API
 */
export const useAuthDeleteUser = <
  TData = Awaited<ReturnType<typeof authDeleteUser>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<DeleteRequest> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, { data: BodyType<DeleteRequest> }, TContext> => {
  const mutationOptions = getAuthDeleteUserMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Laravelのセッションを開始・初期化するためのAPI
 */
export const sessionInitialize = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<void>(
    { url: `/talent/auth/session_initialize`, method: 'GET', signal },
    options,
  )
}

export const getSessionInitializeQueryKey = () => {
  return [`/talent/auth/session_initialize`] as const
}

export const getSessionInitializeQueryOptions = <
  TData = Awaited<ReturnType<typeof sessionInitialize>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof sessionInitialize>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionInitializeQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionInitialize>>> = ({ signal }) =>
    sessionInitialize(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sessionInitialize>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SessionInitializeQueryResult = NonNullable<
  Awaited<ReturnType<typeof sessionInitialize>>
>
export type SessionInitializeQueryError = ErrorType<unknown>

/**
 * @summary Laravelのセッションを開始・初期化するためのAPI
 */

export function useSessionInitialize<
  TData = Awaited<ReturnType<typeof sessionInitialize>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof sessionInitialize>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionInitializeQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSessionInitializeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof sessionInitialize>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionInitialize>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionInitializeQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionInitialize>>> = ({ signal }) =>
    sessionInitialize(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof sessionInitialize>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SessionInitializeSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof sessionInitialize>>
>
export type SessionInitializeSuspenseQueryError = ErrorType<unknown>

/**
 * @summary Laravelのセッションを開始・初期化するためのAPI
 */

export function useSessionInitializeSuspense<
  TData = Awaited<ReturnType<typeof sessionInitialize>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessionInitialize>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionInitializeSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary LINE認証フロー開始時に、セッション値管理のために叩くAPI
 */
export const lineLogin = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<LineLoginUrlResponse>(
    { url: `/talent/auth/line/login`, method: 'POST', signal },
    options,
  )
}

export const getLineLoginMutationOptions = <
  TData = Awaited<ReturnType<typeof lineLogin>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['lineLogin']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof lineLogin>>, void> = () => {
    return lineLogin(requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError, void, TContext>
}

export type LineLoginMutationResult = NonNullable<Awaited<ReturnType<typeof lineLogin>>>

export type LineLoginMutationError = ErrorType<unknown>

/**
 * @summary LINE認証フロー開始時に、セッション値管理のために叩くAPI
 */
export const useLineLogin = <
  TData = Awaited<ReturnType<typeof lineLogin>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, void, TContext> => {
  const mutationOptions = getLineLoginMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary ユーザーがLINE認証を経由して新規登録する際に最初に叩くAPI
 */
export const lineRegistration = (
  registrationRequest: BodyType<RegistrationRequest>,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<LineLoginUrlResponse>(
    {
      url: `/talent/auth/line/registration`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: registrationRequest,
      signal,
    },
    options,
  )
}

export const getLineRegistrationMutationOptions = <
  TData = Awaited<ReturnType<typeof lineRegistration>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<RegistrationRequest> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['lineRegistration']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lineRegistration>>,
    { data: BodyType<RegistrationRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return lineRegistration(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<RegistrationRequest> },
    TContext
  >
}

export type LineRegistrationMutationResult = NonNullable<
  Awaited<ReturnType<typeof lineRegistration>>
>
export type LineRegistrationMutationBody = BodyType<RegistrationRequest>
export type LineRegistrationMutationError = ErrorType<void>

/**
 * @summary ユーザーがLINE認証を経由して新規登録する際に最初に叩くAPI
 */
export const useLineRegistration = <
  TData = Awaited<ReturnType<typeof lineRegistration>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<RegistrationRequest> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, { data: BodyType<RegistrationRequest> }, TContext> => {
  const mutationOptions = getLineRegistrationMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary LINE認証方法追加フロー開始時に、セッション値管理のために叩くAPI
 */
export const lineAddLoginMethod = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<LineLoginUrlResponse>(
    { url: `/talent/auth/line/add_login_method`, method: 'POST', signal },
    options,
  )
}

export const getLineAddLoginMethodMutationOptions = <
  TData = Awaited<ReturnType<typeof lineAddLoginMethod>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['lineAddLoginMethod']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof lineAddLoginMethod>>, void> = () => {
    return lineAddLoginMethod(requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError, void, TContext>
}

export type LineAddLoginMethodMutationResult = NonNullable<
  Awaited<ReturnType<typeof lineAddLoginMethod>>
>

export type LineAddLoginMethodMutationError = ErrorType<unknown>

/**
 * @summary LINE認証方法追加フロー開始時に、セッション値管理のために叩くAPI
 */
export const useLineAddLoginMethod = <
  TData = Awaited<ReturnType<typeof lineAddLoginMethod>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, void, TContext> => {
  const mutationOptions = getLineAddLoginMethodMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary SMSの認証方法を追加するAPI。id_tokenをAuthorizationヘッダに含める
 */
export const addSmsLoginMethod = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<void>(
    { url: `/talent/auth/add_sms_login_method`, method: 'POST', signal },
    options,
  )
}

export const getAddSmsLoginMethodMutationOptions = <
  TData = Awaited<ReturnType<typeof addSmsLoginMethod>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['addSmsLoginMethod']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof addSmsLoginMethod>>, void> = () => {
    return addSmsLoginMethod(requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError, void, TContext>
}

export type AddSmsLoginMethodMutationResult = NonNullable<
  Awaited<ReturnType<typeof addSmsLoginMethod>>
>

export type AddSmsLoginMethodMutationError = ErrorType<void>

/**
 * @summary SMSの認証方法を追加するAPI。id_tokenをAuthorizationヘッダに含める
 */
export const useAddSmsLoginMethod = <
  TData = Awaited<ReturnType<typeof addSmsLoginMethod>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, void, TContext> => {
  const mutationOptions = getAddSmsLoginMethodMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary システム全体にわたって表示するバッチ件数を取得する
 */
export const fetchGlobalBadges = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<GlobalBadges>(
    { url: `/talent/auth/fetch_global_badges`, method: 'GET', signal },
    options,
  )
}

export const getFetchGlobalBadgesQueryKey = () => {
  return [`/talent/auth/fetch_global_badges`] as const
}

export const getFetchGlobalBadgesQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchGlobalBadges>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof fetchGlobalBadges>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFetchGlobalBadgesQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchGlobalBadges>>> = ({ signal }) =>
    fetchGlobalBadges(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchGlobalBadges>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FetchGlobalBadgesQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchGlobalBadges>>
>
export type FetchGlobalBadgesQueryError = ErrorType<void>

/**
 * @summary システム全体にわたって表示するバッチ件数を取得する
 */

export function useFetchGlobalBadges<
  TData = Awaited<ReturnType<typeof fetchGlobalBadges>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof fetchGlobalBadges>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFetchGlobalBadgesQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getFetchGlobalBadgesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchGlobalBadges>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof fetchGlobalBadges>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFetchGlobalBadgesQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchGlobalBadges>>> = ({ signal }) =>
    fetchGlobalBadges(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof fetchGlobalBadges>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FetchGlobalBadgesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchGlobalBadges>>
>
export type FetchGlobalBadgesSuspenseQueryError = ErrorType<void>

/**
 * @summary システム全体にわたって表示するバッチ件数を取得する
 */

export function useFetchGlobalBadgesSuspense<
  TData = Awaited<ReturnType<typeof fetchGlobalBadges>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof fetchGlobalBadges>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFetchGlobalBadgesSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary システム全体にわたって表示するアラートの表示設定を取得する
 */
export const fetchGlobalAlerts = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<GlobalAlerts>(
    { url: `/talent/auth/fetch_global_alerts`, method: 'GET', signal },
    options,
  )
}

export const getFetchGlobalAlertsQueryKey = () => {
  return [`/talent/auth/fetch_global_alerts`] as const
}

export const getFetchGlobalAlertsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchGlobalAlerts>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof fetchGlobalAlerts>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFetchGlobalAlertsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchGlobalAlerts>>> = ({ signal }) =>
    fetchGlobalAlerts(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchGlobalAlerts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FetchGlobalAlertsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchGlobalAlerts>>
>
export type FetchGlobalAlertsQueryError = ErrorType<void>

/**
 * @summary システム全体にわたって表示するアラートの表示設定を取得する
 */

export function useFetchGlobalAlerts<
  TData = Awaited<ReturnType<typeof fetchGlobalAlerts>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof fetchGlobalAlerts>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFetchGlobalAlertsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getFetchGlobalAlertsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchGlobalAlerts>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof fetchGlobalAlerts>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFetchGlobalAlertsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchGlobalAlerts>>> = ({ signal }) =>
    fetchGlobalAlerts(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof fetchGlobalAlerts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FetchGlobalAlertsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchGlobalAlerts>>
>
export type FetchGlobalAlertsSuspenseQueryError = ErrorType<void>

/**
 * @summary システム全体にわたって表示するアラートの表示設定を取得する
 */

export function useFetchGlobalAlertsSuspense<
  TData = Awaited<ReturnType<typeof fetchGlobalAlerts>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof fetchGlobalAlerts>>, TError, TData>
  request?: SecondParameter<typeof axiosInstance>
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFetchGlobalAlertsSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary 最終アクセス日時を更新する
 */
export const updateLastAccess = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<void>(
    { url: `/talent/auth/update_last_access`, method: 'POST', signal },
    options,
  )
}

export const getUpdateLastAccessMutationOptions = <
  TData = Awaited<ReturnType<typeof updateLastAccess>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['updateLastAccess']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateLastAccess>>, void> = () => {
    return updateLastAccess(requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError, void, TContext>
}

export type UpdateLastAccessMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateLastAccess>>
>

export type UpdateLastAccessMutationError = ErrorType<void>

/**
 * @summary 最終アクセス日時を更新する
 */
export const useUpdateLastAccess = <
  TData = Awaited<ReturnType<typeof updateLastAccess>>,
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, void, TContext> => {
  const mutationOptions = getUpdateLastAccessMutationOptions(options)

  return useMutation(mutationOptions)
}
