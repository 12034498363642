import { atom } from 'jotai'
import { atomWithQuery, queryClientAtom } from 'jotai-tanstack-query'

import { getGetMeQueryKey, getMe } from '@/api/__generated__/talent/auth/auth'
import type { MeTalent } from '@/api/__generated__/talent/zCareerAPIForTalent.schemas'
import type { ErrorType } from '@/api/axios'
import { sessionCookieDeleteConfig } from '@/config'

export const meAtom = atomWithQuery(() => ({
  queryKey: getGetMeQueryKey(),
  queryFn: getMe,
  onError: (error: ErrorType<unknown>) => {
    if (error.response?.status === 401) {
      document.cookie = sessionCookieDeleteConfig
    }
  },
  retry: false,
  refetchOnMount: false, // WHY: 連続で401が返る可能性があるため
  refetchOnWindowFocus: false,
}))
meAtom.debugLabel = 'me'

// NOTE: TanStack Query移行で既存と互換性を保つため、meAtomからdataを取得し、セットできるようにしている
export const authMeAtom = atom(
  (get) => get(meAtom).data ?? null,
  (get, _set, newValue: MeTalent | null) => {
    const queryClient = get(queryClientAtom)
    queryClient.setQueryData(getGetMeQueryKey(), newValue)
  },
)
authMeAtom.debugLabel = 'authMe'

/** 登録完了処理中かどうか */
export const isCompletingRegistrationAtom = atom(false)
isCompletingRegistrationAtom.debugLabel = 'isCompletingRegistration'
