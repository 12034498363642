import { Button, Flex, Text } from '@chakra-ui/react'
import Image from 'next/image'

import { useScoutNotice } from '@/modules/components/Notices/ScoutNotice/useScoutNotice'

export interface ScoutNoticeProps {
  firstName: string
  scoutNoticeType: ScoutNoticeType | null
  unreadScoutsCount?: number
  scoutDeliveryHour?: string
  clickNameSuffix?: string
}
export enum ScoutNoticeType {
  NO_SCOUT_PROFILE = 'NO_SCOUT_PROFILE',
  RECEIVED_SCOUT = 'RECEIVED_SCOUT',
}

export const ScoutNotice = ({
  firstName,
  scoutNoticeType,
  unreadScoutsCount,
  scoutDeliveryHour,
  clickNameSuffix = '',
}: ScoutNoticeProps) => {
  const { bgColor, characterIconSource, messageText, alertButtonValue } = useScoutNotice({
    unreadScoutsCount,
    scoutNoticeType,
    firstName,
    scoutDeliveryHour,
  })
  const suffix = clickNameSuffix ? `_${clickNameSuffix}` : ''

  if (!scoutNoticeType) return
  return (
    <Flex alignItems="flex-start" bg={bgColor} borderRadius="8px" gap="10px" p="8px">
      <Image alt="キャラクター" height={48} priority src={characterIconSource} width={48} />
      <Flex flexDirection="column" gap="8px">
        <Text textStyle="talent.body.lg">{messageText}</Text>
        <Flex justifyContent="right">
          <Button
            as="a"
            bg="white"
            borderRadius="100px"
            data-gtm-click-item={`scout_profile_banner${suffix}`}
            fontSize="12px"
            h="32px"
            href={alertButtonValue.link}
            p="10px 16px"
            variant="outline"
            w="fit-content"
          >
            {alertButtonValue.text}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
