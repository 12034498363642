import React from 'react'
import * as Sentry from '@sentry/nextjs'
import type { AxiosError } from 'axios'
import { isAxiosError } from 'axios'

import ErrorPage from '@/pages/_error'

type ErrorType = (Error & { statusCode?: number }) | AxiosError
interface ErrorFallbackProps {
  error: ErrorType
}

const getStatusCode = (error: ErrorType) => {
  if (isAxiosError(error)) {
    if (error.status) {
      return error.status
    }
    return 500
  }
  if (error.statusCode) return error.statusCode
  return 500
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error }) => {
  console.error('console at ErrorFallback Component')
  Sentry.captureException(error)

  return (
    <ErrorPage
      errorMessage={error.message || 'An unexpected error occurred'}
      sendedToSentry
      statusCode={getStatusCode(error)}
    />
  )
}
