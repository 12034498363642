import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ChakraProvider } from '@chakra-ui/react'
import { dehydrate, HydrationBoundary, isServer } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import type { GetServerSidePropsContext } from 'next'
import type { AppPropsWithLayout } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { NuqsAdapter } from 'nuqs/adapters/next/pages'
import { PrimeReactProvider } from 'primereact/api'

import '@/utils/datadogRum'

import { getGetMeQueryKey, getMe } from '@/api/__generated__/talent/auth/auth'
import type { MeTalent } from '@/api/__generated__/talent/zCareerAPIForTalent.schemas'
import { ErrorFallback } from '@/components/error/ErrorFallback'
import { defaultMetaTitle, sessionCookieDeleteConfig, sessionCookieName } from '@/config'
import { unhandledrejectionErrorHandler } from '@/errorHandler/unhandledrejection/unhandledrejectionErrorHandler'
import { useLastAccess } from '@/hooks/talent/useLastAccess'
import { useAuthCheck } from '@/hooks/useAuthGlobal'
import { getQueryClient, QueryProviders } from '@/lib/queryClient'
import { ErrorPageWrapper } from '@/modules/components/ErrorPageWrapper/ErrorPageWrapper'
import { JotaiDevTool } from '@/modules/components/JotaiDevTool'
import { CustomToast } from '@/modules/components/toast/CustomToast/CustomToast'
import { isProd, isStg } from '@/modules/utils/env'
import { useInitGTM } from '@/modules/utils/gtm/gtmEventActions'
import serverSideLogger from '@/modules/utils/serverSideLogger'
import { theme } from '@/modules/utils/theme'
import { handleUtmCookie } from '@/utils/clientCookieHandler/clientCookieHandler'
import { ScrollRestoration } from '@/utils/scrollRestoration'

import '../styles/globals.css'

type Props = AppPropsWithLayout<{
  me: MeTalent | null
  statusCode?: number
  dehydratedState?: ReturnType<typeof dehydrate>
}>

/**
 * データフローの流れ:
 * 1. サーバーサイド: getInitialPropsでユーザー情報を取得
 * 2. TanStack Queryのキャッシュに保存
 * 3. dehydrateでクライアントに渡せる形に変換
 * 4. クライアントサイド: HydrationBoundaryでデータを復元
 * 5. useAuthCheckでグローバルステートに同期
 */
const MyApp = ({ Component, pageProps }: Props) => {
  if (isProd || isStg) {
    // GTMの初期化
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useInitGTM()
  }

  // SSRで取得したログイン情報をglobal stateに保存
  useAuthCheck(pageProps.me)

  const { updateLastAccess } = useLastAccess()

  const router = useRouter()
  const getLayout = Component.getLayout || ((page: ReactNode) => page)

  useEffect(() => {
    updateLastAccess()
    handleUtmCookie()
    window.addEventListener('unhandledrejection', (event) => unhandledrejectionErrorHandler(event))
  }, [])

  // 遷移ごとのコールバックを定義
  useEffect(() => {
    router.events.on('routeChangeStart', updateLastAccess)
    return () => {
      router.events.off('routeChangeStart', updateLastAccess)
    }
  }, [router.events, updateLastAccess])

  if (pageProps?.statusCode === 503) {
    return <ErrorPageWrapper statusCode={pageProps.statusCode} />
  }

  return (
    <PrimeReactProvider>
      {getLayout(
        <NuqsAdapter>
          <Component {...pageProps} />
        </NuqsAdapter>,
      )}
    </PrimeReactProvider>
  )
}

const AppContent = ({ children }: { children: React.ReactNode }) => (
  <>
    {!isProd && !isStg && <JotaiDevTool />}
    <Head>
      <title>{defaultMetaTitle}</title>
      <meta content="width=device-width,initial-scale=1.0,maximum-scale=1.0" name="viewport" />
    </Head>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ScrollRestoration />
      <CustomToast />
      {children}
    </ErrorBoundary>
  </>
)

const MyAppProvider = (props: Props) => {
  const { pageProps } = props
  return (
    <ChakraProvider resetCSS={false} theme={theme}>
      <QueryProviders>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <AppContent>
            <MyApp {...props} />
          </AppContent>
        </HydrationBoundary>
      </QueryProviders>
    </ChakraProvider>
  )
}

MyAppProvider.getInitialProps = async ({ ctx }: { ctx: GetServerSidePropsContext }) => {
  const token = ctx.req?.cookies?.[sessionCookieName]
  const queryClient = getQueryClient()

  if (!token || !isServer) {
    return {
      pageProps: {
        me: null,
        dehydratedState: dehydrate(queryClient),
      },
    }
  }

  try {
    const data = await queryClient.fetchQuery({
      queryKey: getGetMeQueryKey(),
      queryFn: () => getMe({ headers: { Cookie: `${sessionCookieName}=${token}` } }),
    })

    return {
      pageProps: {
        me: data ?? null,
        dehydratedState: dehydrate(queryClient),
      },
    }
  } catch (err) {
    if (err instanceof AxiosError) {
      if (err?.response?.status === 503) {
        ctx.res.statusCode = 503
        return {
          pageProps: {
            me: null,
            statusCode: 503,
            dehydratedState: dehydrate(queryClient),
          },
        }
      }
      if (err?.response?.status === 401) {
        ctx.res.setHeader('Set-Cookie', sessionCookieDeleteConfig)
      }
    }
    serverSideLogger.error({
      msg: {
        err,
      },
    })

    return {
      pageProps: {
        me: null,
        dehydratedState: dehydrate(queryClient),
      },
    }
  } finally {
    ctx.res?.setHeader('Content-Type', 'text/html')
  }
}

export default MyAppProvider
