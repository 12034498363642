import { useQueryClient } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'

import {
  getFetchGlobalAlertsQueryKey,
  useFetchGlobalAlerts,
} from '@/api/__generated__/talent/auth/auth'
import { authMeAtom } from '@/globalState/auth'

export const useGlobalAlerts = () => {
  const queryClient = useQueryClient()
  const me = useAtomValue(authMeAtom)

  const { data, isLoading, refetch } = useFetchGlobalAlerts({
    query: { queryKey: getFetchGlobalAlertsQueryKey(), enabled: !!me },
  })

  /**
   * キャッシュしているshowLineFriendNoticeの値をAPIフェッチを介さずに更新する
   */
  const localRefetchShowLineFriendNotice = (value: boolean) => {
    queryClient.setQueryData(getFetchGlobalAlertsQueryKey(), (oldData) =>
      oldData ? { ...oldData, showLineFriendNotice: value } : undefined,
    )
  }

  return {
    isLoadingGlobalAlerts: isLoading,
    showLineFriendNotice: data?.showLineFriendNotice,
    mutateGlobalAlerts: refetch,
    localRefetchShowLineFriendNotice,
  }
}
