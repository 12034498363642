import type { FC } from 'react'
import React from 'react'

interface ScoutIconProps {
  width?: string
  height?: string
  color?: string
  children?: React.ReactNode
}

export const ScoutIcon: FC<ScoutIconProps> = ({
  width,
  height,
  color = 'var(--chakra-colors-_element-linkPrimary)',
  children,
}) => {
  return (
    <svg
      fill="none"
      height={height || '32'}
      viewBox="0 0 28 28"
      width={width || '32'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_29048_2260)">
        <path
          d="M9.92149 22.3405C11.1527 22.9435 12.537 23.282 14.0004 23.282C19.1287 23.282 23.2859 19.1248 23.2859 13.9965C23.2859 8.86822 19.1287 4.71094 14.0004 4.71094C8.87212 4.71094 4.71484 8.86822 4.71484 13.9965C4.71484 15.9002 5.28772 17.6701 6.27047 19.1431M9.92149 22.3405L4.71484 23.282L6.27047 19.1431M9.92149 22.3405L9.92903 22.3392M6.27047 19.1431L6.27196 19.1392"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_29048_2260">
          <rect fill="white" height="19.9996" transform="translate(4 4)" width="19.9996" />
        </clipPath>
      </defs>
      {children}
    </svg>
  )
}
