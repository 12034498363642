import type { FC } from 'react'
import type { ButtonProps } from '@chakra-ui/react'
import { Box, Button } from '@chakra-ui/react'

export type MenuButtonProps = Omit<ButtonProps, 'children'> & {
  width?: string | number
  height?: string | number
}

export const MenuButton: FC<MenuButtonProps> = ({ width = '85px', height = '42px', ...props }) => {
  return (
    <Box display="inline-block" height={height} position="relative" width={width}>
      <Button
        _active={{
          opacity: 0.8,
        }}
        _hover={{
          '& rect:first-of-type': {
            fill: 'var(--chakra-colors-element-linkPrimary)',
          },
          '& path': {
            fill: 'var(--chakra-colors-text-invertPrimary)',
          },
        }}
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        left="0"
        padding="0"
        position="absolute"
        top="0"
        transition="all 0.2s"
        variant="unstyled"
        width="100%"
        {...props}
      >
        <svg
          fill="none"
          height="100%"
          style={{
            display: 'block',
          }}
          viewBox="0 0 85 42"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect fill="var(--chakra-colors-text-invertPrimary)" height="42" rx="21" width="85" />
          <rect
            height="41"
            rx="20.5"
            stroke="var(--chakra-colors-element-linkPrimary)"
            strokeOpacity="0.92"
            width="84"
            x="0.5"
            y="0.5"
          />
          <path
            d="M18.0733 17.726V26H16.6593V16.2H18.9553L23.3933 24.992L27.8313 16.2H30.1133V26H28.6993V17.754L24.5553 26H22.2313L18.0733 17.726ZM31.3702 16.2H42.2202V17.558H32.7702V20.484H40.8622V21.828H32.7702V24.642H42.2202V26H31.3702V16.2ZM43.4835 16.2H45.8075L52.9055 25.076L52.9195 16.2H54.3195V26H51.8695L44.8835 17.292V26H43.4835V16.2ZM60.9591 26C60.0725 26 59.2138 25.804 58.3831 25.412C57.5525 25.0107 56.8758 24.474 56.3531 23.802C55.8398 23.1207 55.5831 22.3833 55.5831 21.59V16.2H56.9551V21.548C56.9551 22.0893 57.1558 22.5933 57.5571 23.06C57.9585 23.5267 58.4671 23.9 59.0831 24.18C59.7085 24.46 60.3338 24.6 60.9591 24.6C61.5845 24.6 62.2051 24.46 62.8211 24.18C63.4465 23.9 63.9598 23.5267 64.3611 23.06C64.7625 22.5933 64.9631 22.0893 64.9631 21.548V16.2H66.3351V21.59C66.3351 22.3833 66.0738 23.1207 65.5511 23.802C65.0378 24.474 64.3658 25.0107 63.5351 25.412C62.7045 25.804 61.8458 26 60.9591 26Z"
            fill="var(--chakra-colors-element-linkPrimary)"
          />
        </svg>
      </Button>
    </Box>
  )
}
