import { memo, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react'
import Image from 'next/image'
import { useRouter } from 'next/router'

import LINELogo from '../assets/icon/LINE_LOGO.svg'
import { MODULE_URL } from '../const/url'
import type { GTMClickItemName } from '../utils/gtm/types/common'

import { Badge } from './Brand/Badge'
import { LogoSm } from './Brand/LogoSm'
import { MenuButton } from './Brand/MenuButton'
import Menu from './HeaderMenu/Menu'
import { HeartLikeFavoriteLoveIcon } from './Icon/HeartLikeFavoriteLoveIcon'
import { ScoutIcon } from './Icon/ScoutIcon'
import { ChakraLink } from './ChakraLink'
import { ChakraLinkWithoutAs } from './ChakraLinkWithoutAs'

interface Props {
  mode: 'talent' | 'guide'
  isLocal: boolean
  talentAppUrl?: string
  guideAppUrl?: string
  addLineFriendUrl?: string
  isLogin?: boolean
  isPreview?: boolean
  keepCount?: number
  unreadScoutCount?: number
  showLineFriendNotice?: boolean
  talentName?: string
  isScoutReady?: boolean
}

// eslint-disable-next-line react/display-name
const Header: React.FC<Props> = memo(
  ({
    mode,
    isLocal,
    talentAppUrl,
    guideAppUrl,
    addLineFriendUrl,
    isLogin,
    isPreview,
    keepCount,
    unreadScoutCount,
    showLineFriendNotice,
    talentName,
    isScoutReady,
  }) => {
    const router = useRouter()
    const [cookies] = useCookies(['no_promo_demo_250110'])
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [isClient, setIsClient] = useState(false)
    const [isLoginHeader, setIsLoginHeader] = useState(false)

    const hrefTalentHome = useMemo(
      () => (isLocal ? `${talentAppUrl}${MODULE_URL.ROOT}` : MODULE_URL.ROOT),
      [isLocal, talentAppUrl],
    )

    useEffect(() => {
      setIsClient(true)
    }, [])

    // ログイン中に新規登録・ログインボタンが一瞬表示させてしまうため、
    // isClient有効後にログイン状態を判定する
    useEffect(() => {
      // no_promo_demo_250110がクッキーに入っていることを確認
      if (cookies.no_promo_demo_250110) {
        return
      }

      if (isClient) {
        setIsLoginHeader((isLogin === undefined || !isLogin) && router.asPath !== MODULE_URL.ROOT)
      }
    }, [isClient, isLogin, router.asPath])

    return (
      <>
        {/* what: LINE認証のみかつLINE友達追加をしてない場合にのみ表示させる友達追加システムアラート */}
        {isClient && isLogin === true && showLineFriendNotice && (
          <Flex
            alignItems="center"
            bg="white"
            borderBottom="1px solid"
            borderBottomColor="_border.primary"
            gap={1}
            justifyContent="space-between"
            m="0 auto"
            maxW="640px"
            p="8px 8px 8px 16px"
          >
            <Flex alignItems="center" gap="8px">
              <Image alt="LINE Logo" height={32} src={LINELogo.src} width={32} />
              <Text fontSize="12px" lineHeight="16px" whiteSpace="pre-wrap">
                {`Zキャリア公式LINEなら
スカウト通知を受け取れる`}
              </Text>
            </Flex>
            <ChakraLink href={addLineFriendUrl ?? ''} isExternal linkTo="outside">
              <Button
                _hover={{ bg: '#06C755' }}
                bg="#06C755"
                borderRadius="100px"
                color="white"
                fontSize="12px"
                fontWeight="bold"
                h="32px"
                p="10px 16px"
                w="fit-content"
              >
                LINEで友達追加
              </Button>
            </ChakraLink>
          </Flex>
        )}
        <Flex
          alignItems="center"
          as="header"
          bg="white"
          borderBottom="1px solid"
          borderBottomColor="_border.primary"
          height={isPreview ? '64px' : 'inherit'}
          justifyContent="space-between"
          m="0 auto"
          maxW="640px"
          p={2}
        >
          {isPreview ? (
            <LogoSm height="34px" width="36px" />
          ) : (
            <ChakraLinkWithoutAs
              data-gtm-click-item={'header_logo' as GTMClickItemName}
              href={hrefTalentHome}
              linkTo="talent"
            >
              <LogoSm height="34px" width="36px" />
            </ChakraLinkWithoutAs>
          )}
          {isPreview ? (
            <div />
          ) : (
            <Flex alignItems="center" color="_text.primary" gap={3}>
              {mode === 'talent' && (
                <Flex>
                  {isClient && isLogin && (
                    <ChakraLink
                      _hover={{ textDecoration: 'none' }}
                      data-gtm-click-item={'header_scout_nav' as GTMClickItemName}
                      href="/scout"
                      linkTo="talent"
                    >
                      <Flex
                        alignItems="center"
                        cursor="pointer"
                        flexDirection="column"
                        gap="2px"
                        height="48px"
                        justifyContent="center"
                        position="relative"
                        width="44px"
                      >
                        <Box display="inline-block" position="relative">
                          <ScoutIcon height="28px" width="28px" />
                          <Badge count={unreadScoutCount ?? 0} />
                        </Box>
                      </Flex>
                    </ChakraLink>
                  )}
                  <ChakraLink
                    _hover={{ textDecoration: 'none' }}
                    data-gtm-click-item={'header_keep_nav' as GTMClickItemName}
                    href="/job/keep-list"
                    linkTo="talent"
                  >
                    <Flex
                      alignItems="center"
                      cursor="pointer"
                      flexDirection="column"
                      gap="2px"
                      height="48px"
                      justifyContent="center"
                      position="relative"
                      width="44px"
                    >
                      <Box display="inline-block" position="relative">
                        <HeartLikeFavoriteLoveIcon height="28px" width="28px" />
                        <Badge count={keepCount ?? 0} />
                      </Box>
                    </Flex>
                  </ChakraLink>
                </Flex>
              )}
              <MenuButton
                data-gtm-click-item={'header_menu_nav' as GTMClickItemName}
                height="42px"
                onClick={onOpen}
                width="85px"
              />
            </Flex>
          )}
        </Flex>
        {isLogin !== undefined && isLoginHeader && (
          <Flex gap={1} m="0 auto" maxW="640px" p="8px 16px">
            <ChakraLink href="/registration/agent" linkTo="talent" w="100%">
              <Button
                _hover={{ bg: '_brand.neonGreen' }}
                bg="_brand.neonGreen"
                borderColor="_border.black"
                borderRadius="100px"
                fontSize="14px"
                fontWeight="bold"
                p="10px 24px"
                size="md"
                variant="outline"
                w="100%"
              >
                会員登録（無料）
              </Button>
            </ChakraLink>
            <ChakraLink href="/login" linkTo="talent" w="100%">
              <Button
                _hover={{ bg: '_surface.reversePrimary', color: '_text.invertPrimary' }}
                bg="_surface.primary"
                border="1px solid"
                borderColor="_border.black"
                borderRadius="100px"
                fontSize="14px"
                fontWeight="bold"
                p="10px 24px"
                size="md"
                w="100%"
              >
                ログイン
              </Button>
            </ChakraLink>
          </Flex>
        )}
        <Menu
          guideAppUrl={guideAppUrl}
          isLocal={isLocal}
          isLogin={isLogin}
          isOpen={isOpen}
          isScoutReady={isScoutReady}
          keptJobsCount={keepCount ?? 0}
          mode={mode}
          onClose={onClose}
          talentAppUrl={talentAppUrl}
          talentName={talentName}
          unreadScoutCount={unreadScoutCount ?? 0}
        />
      </>
    )
  },
)

export default Header
