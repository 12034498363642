/* eslint-disable no-underscore-dangle */
import React from 'react'
import { HiCheckCircle, HiExclamationCircle } from 'react-icons/hi'
import { Box, useTheme } from '@chakra-ui/react'
import { toast, Toaster } from 'sonner'

import { useWindowSize } from '@/modules/hooks/useWindowSize'

import styles from './CustomToast.module.scss'

// NOTE: エラートーストのデフォルト設定を上書き(エラートーストの表示時間を無限にする)
const originalError = toast.error
toast.error = (message, data) => {
  return originalError(message, { ...data, duration: Infinity })
}

export const CustomToast = () => {
  const theme = useTheme()
  const { isPcView } = useWindowSize()

  return (
    <Toaster
      icons={{
        success: (
          <Box mt={1}>
            <HiCheckCircle color={theme.colors._brand.green} size={24} />
          </Box>
        ),
        error: (
          <Box mt={1}>
            <HiExclamationCircle color={theme.colors._text.error} size={24} />
          </Box>
        ),
      }}
      position={isPcView ? 'top-right' : 'top-center'}
      toastOptions={{
        unstyled: false,
        duration: 4000,
        style: {
          background: theme.colors.surface.primary,
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0px 4px 16px 0px #00000029',
          border: `1px solid ${theme.colors.border.primary}`,
          display: 'flex',
          gap: '8px',
          fontSize: '14px',
          lineHeight: '24px',
          alignItems: 'flex-start',
          width: isPcView ? '100%' : '',
          filter: '',
          fontFamily: `"YakuHanJPs",-apple-system,BlinkMacSystemFont,"Segoe UI","Hiragino Sans","Hiragino Kaku Gothic ProN","Meiryo",sans-serif`,
        },
        classNames: {
          error: styles['toast-error'],
          actionButton: styles['toast-action-button'],
        },
      }}
    />
  )
}
