import React from 'react'
import type { ButtonProps } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'

import type { GTMClickItemName } from '../../utils/gtm/types/common'

type Mode = 'primary' | 'secondary' | 'tertiary' | 'conversion'

interface Props extends ButtonProps {
  mode: Mode
  isDisabled?: boolean
  'data-gtm-click-item'?: GTMClickItemName
}
const commonStyle = {
  fontSize: '16px',
  lineHeight: '16px',
  padding: '8px 16px',
  borderRadius: '100px',
  height: '48px',
}

export const MainButton: React.FC<Props> = ({ children, mode, isDisabled, ...props }) => {
  // ButtonProps以外のpropsはButtonに渡されるとWarnになったりするので上記のように取り出しておく
  if (mode === 'primary') {
    return (
      <Button
        {...commonStyle}
        _disabled={{ opacity: '0.2' }}
        _hover={{ opacity: '0.8' }}
        bg="_element.linkPrimary"
        border="none"
        color="_text.invertPrimary"
        cursor={isDisabled ? 'not-allowed' : undefined}
        isDisabled={isDisabled}
        {...props}
      >
        {children}
      </Button>
    )
  }
  if (mode === 'secondary') {
    return (
      <Button
        {...commonStyle}
        _disabled={{ opacity: '0.2' }}
        _hover={{ opacity: '0.8' }}
        bg="_surface.primary"
        border="1px solid"
        borderColor="_border.black"
        color="_text.primary"
        cursor={isDisabled ? 'not-allowed' : undefined}
        isDisabled={isDisabled}
        {...props}
      >
        {children}
      </Button>
    )
  }
  if (mode === 'tertiary') {
    return (
      <Button
        {...commonStyle}
        _disabled={{ opacity: '0.2' }}
        _hover={{ bg: '_surface.secondary', color: '_text.secondary' }}
        bg="_surface.primary"
        border="1px solid"
        borderColor="_border.primary"
        color="_text.primary"
        cursor={isDisabled ? 'not-allowed' : undefined}
        isDisabled={isDisabled}
        {...props}
      >
        {children}
      </Button>
    )
  }
  return (
    <Button
      {...commonStyle}
      _disabled={{ opacity: '0.2' }}
      _hover={{ opacity: '0.8' }}
      bg="_brand.neonGreen"
      border="1px solid"
      borderColor="_border.black"
      color="_text.primary"
      cursor={isDisabled ? 'not-allowed' : undefined}
      isDisabled={isDisabled}
      {...props}
    >
      {children}
    </Button>
  )
}
