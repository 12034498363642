import React, { memo } from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Flex, Image as ChakraImage, SimpleGrid, Text, VStack } from '@chakra-ui/react'

import CopyMarkRoxx from '@/modules/assets/icon/Brand/CopyMarkRoxx.svg'
import RoxxLogo from '@/modules/assets/icon/Brand/RoxxLogo.svg'
import Zcareer from '@/modules/assets/icon/Brand/Zcareer.svg'
import Zzzz from '@/modules/assets/icon/Brand/Zzzz.svg'
import Pmark from '@/modules/assets/images/pmark.png'
import { ChakraLink } from '@/modules/components/ChakraLink'
import {
  companyDirect,
  inquiryPage,
  operatingCompanyPage,
  privacyPolicyPage,
} from '@/modules/constant'
import type { GTMClickItemName } from '@/modules/utils/gtm/types/common'

import styles from './Footer.module.scss'

interface Props {
  isPreview?: boolean
  showAgentConnectAppeal?: boolean // Why: 応募完了画面等、ACへの導線がユーザーに混乱を生む画面では当該訴求を消したい
  isLocal: boolean
  talentAppUrl?: string
  guideAppUrl?: string
}

// eslint-disable-next-line react/display-name
const Footer: React.FC<Props> = memo(({ isPreview, talentAppUrl }) => {
  const gridStyle = {
    bg: 'white',
    width: '100%',
    height: '40px',
    p: '32px 0 16px 20px',
    fontSize: '12px',
    cursor: 'pointer',
  }
  const boxWithExternalLink = (path: string, text: string) => {
    if (isPreview) {
      return (
        <Flex alignItems="center" textAlign="center">
          <Text fontSize="12px" lineHeight="20px" mr="10px">
            {text}
          </Text>
          <ExternalLinkIcon boxSize="16px" color="gray.400" lineHeight="20px" />
        </Flex>
      )
    }
    return (
      <ChakraLink
        _hover={{ textDecoration: 'none' }}
        href={path}
        isExternal
        linkTo="outside"
        target="_blank"
      >
        <Flex alignItems="center" textAlign="center">
          <Text fontSize="12px" lineHeight="20px" mr="10px">
            {text}
          </Text>
        </Flex>
      </ChakraLink>
    )
  }
  return (
    <Flex
      alignItems="center"
      as="footer"
      bg="surface.primary"
      flexDirection="column"
      id="footer"
      justifyContent="center"
      margin="0 auto"
      maxWidth="640px"
      mt="1px"
      paddingTop="60px"
      width="100%"
    >
      <Flex maxWidth="640px" overflow="hidden" width="100%">
        <Box>
          <Flex className={styles.logoList} height="70px" width="1200px">
            <ChakraImage alt="logo" paddingRight="20px" src={Zzzz.src} />
            <ChakraImage alt="logo" src={Zzzz.src} />
          </Flex>
        </Box>
      </Flex>
      <Flex flexDirection="column" justifyContent="start" paddingTop="70px" width="90%">
        <ChakraLink
          _hover={{ textDecoration: 'none' }}
          data-gtm-click-item={'menu_job_list_link' as GTMClickItemName}
          href="/job"
          linkTo="talent"
        >
          <Flex alignItems="center" height="70px" justifyContent="space-between" p="5px 3px">
            <Text fontSize="16px" fontWeight="bold" lineHeight="1.5" paddingLeft="16px">
              求人を探す
            </Text>
          </Flex>
        </ChakraLink>

        <ChakraLink
          _hover={{ textDecoration: 'none' }}
          data-gtm-click-item={'menu_job_list_link' as GTMClickItemName}
          href="/guide"
          linkTo="guide"
        >
          <Flex alignItems="center" height="70px" justifyContent="space-between" p="5px 3px">
            <Text fontSize="16px" fontWeight="bold" lineHeight="1.5" paddingLeft="16px">
              就職・転職ガイド
            </Text>
          </Flex>
        </ChakraLink>
      </Flex>

      <SimpleGrid columns={1} paddingTop="50px" spacingX="1px" spacingY="1px" w="90%">
        <Box {...gridStyle}>{boxWithExternalLink(`${talentAppUrl}/docs/terms`, '利用規約')}</Box>
        <Box {...gridStyle}>{boxWithExternalLink(privacyPolicyPage, '個人情報保護方針')}</Box>
        {/* 初期段階では削除 */}
        {/* <Box {...gridStyle}>{boxWithExternalLink(externalContent, '外部コンテンツの利用')}</Box> */}
        <Box {...gridStyle}>{boxWithExternalLink(inquiryPage, 'お問い合わせ')}</Box>
        <Box {...gridStyle}>{boxWithExternalLink(companyDirect, '求人掲載')}</Box>
        <Box {...gridStyle}>{boxWithExternalLink(operatingCompanyPage, '運営会社')}</Box>
      </SimpleGrid>
      <VStack justifyContent="start" padding="50px 20px 50px 20px" width="90%">
        <Flex justifyContent="space-between" width="100%">
          <ChakraImage alt="logo" src={RoxxLogo.src} />
          <Box>
            <ChakraImage alt="logo" src={Pmark.src} width="60px" />
          </Box>
        </Flex>
        <Box width="100%">
          <ChakraImage alt="logo" src={CopyMarkRoxx.src} />
        </Box>
      </VStack>
      <Flex maxWidth="640px" overflow="hidden" paddingBlock="50px" width="100%">
        <Box>
          <Flex className={styles.logoList} height="60px" width="2000px">
            <ChakraImage alt="logo" paddingRight="20px" src={Zcareer.src} />
            <ChakraImage alt="logo" src={Zcareer.src} />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
})

export default Footer
