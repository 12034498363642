import { extendTheme } from '@chakra-ui/react'

import { BRAND_COLOR, CHAKRA_COLOR } from '../designTheme/const'

export const theme = extendTheme({
  components: {
    Select: {
      baseStyle: {
        field: {
          _placeholder: {
            color: '#A0AEC0',
          },
          '&::placeholder': {
            color: '#A0AEC0',
          },
        },
      },
    },
    Drawer: {
      sizes: {
        scoutTargetSettingDrawerSize: {
          dialog: {
            maxW: '480px',
          },
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: 'gray.100',
        color: 'gray.800',
        fontFamily: `"YakuHanJPs","-apple-system","BlinkMacSystemFont","Segoe UI","Hiragino Sans","Hiragino Kaku Gothic ProN","Meiryo",sans-serif;`,
        textDecoration: 'none',
      },
    },
  },
  colors: {
    brand: {
      red: '#FF6774',
      'red-right': '#FFE1E3',
      'red-deep': '#A34B57',
      yellow: '#EFBC37',
      'yellow-right': '#FCF2D7',
      'yellow-deep': '#9A7E33',
      green: '#4BD281',
      'green-right': '#DBF6E6',
      'green-deep': '#378B5F',
      blue: '#25A3DB',
      'blue-right': '#D3EDF8',
      'blue-deep': '#216F95',
      purple: '#B399E9',
      'purple-right': '#F0EBFB',
      'purple-deep': '#76699D',
      neonGreen: '#99FF00',
    },
    text: {
      primary: '#1A202C',
      secondary: '#4A5568',
      placeholder: '#A0AEC0',
      invert: 'white',
      invertPrimary: '#FFFFFF',
      link: '#25A3DB',
      error: 'red.500',
    },
    border: {
      primary: '#E2E8F0',
      black: '#000000E5',
      borderForeground: '#09090B',
    },
    element: {
      linkPrimary: '#000000',
      like: '#E260FF',
    },
    surface: {
      primary: '#FFFFFF',
      'reverse-primary': '#1A202C',
      secondary: '#EDF2F7',
      'reverse-secondary': '#2D3748',
      keep: '#FF6774',
      reverse: {
        secondary: '#2D3748',
      },
    },
    'text-foreground': '#09090B',
    _brand: {
      green: BRAND_COLOR.GREEN,
      greenRight: BRAND_COLOR.GREEN_RIGHT,
      neonGreen: BRAND_COLOR.NEON_GREEN,
    },
    _element: {
      linkPrimary: CHAKRA_COLOR.BLACK,
      secondary: BRAND_COLOR.BLUE,
      like: BRAND_COLOR.NEON_RED,
      notice: BRAND_COLOR.NEON_RED,
      error: CHAKRA_COLOR.RED[500],
    },
    _text: {
      primary: CHAKRA_COLOR.BLACK,
      secondary: CHAKRA_COLOR.GRAY[600],
      placeholder: CHAKRA_COLOR.GRAY[400],
      invertPrimary: CHAKRA_COLOR.WHITE,
      invertSecondary: CHAKRA_COLOR.GRAY[300],
      link: BRAND_COLOR.BLUE,
      error: CHAKRA_COLOR.RED[500],
    },
    _border: {
      black: CHAKRA_COLOR.BLACK,
      primary: CHAKRA_COLOR.GRAY[200],
      primaryHover: CHAKRA_COLOR.GRAY[300],
      reversePrimary: CHAKRA_COLOR.GRAY[600],
      reverseHover: CHAKRA_COLOR.GRAY[500],
    },

    _surface: {
      primary: CHAKRA_COLOR.WHITE,
      secondary: CHAKRA_COLOR.GRAY[100],
      accent: BRAND_COLOR.EARTH_YELLOW,
      reversePrimary: CHAKRA_COLOR.BLACK,
      reverseSecondary: CHAKRA_COLOR.GRAY[700],
    },
  },
  // テキストスタイルの定義
  textStyles: {
    // companyやadminを含む
    biz: {
      heading: {
        lg: {
          fontSize: '20px',
          lineHeight: '140%',
          fontWeight: 'bold',
        },
        md: {
          fontSize: '18px',
          lineHeight: '140%',
          fontWeight: 'bold',
        },
        sm: {
          fontSize: '16px',
          lineHeight: '140%',
          fontWeight: 'bold',
        },
      },
      body: {
        lg: {
          fontSize: '16px',
          lineHeight: '150%',
        },
        md: {
          fontSize: '14px',
          lineHeight: '150%',
        },
        sm: {
          fontSize: '12px',
          lineHeight: '150%',
        },
      },
      help: {
        fontSize: '12px',
        lineHeight: '150%',
      },
      table: {
        head: {
          fontSize: '12px',
          lineHeight: '150%',
          fontWeight: 'bold',
        },
        item: {
          fontSize: '14px',
          lineHeight: '150%',
        },
      },
    },
    talent: {
      heading: {
        lg: {
          fontSize: '18px',
          lineHeight: '150%',
          fontWeight: 'bold',
        },
        md: {
          fontSize: '16px',
          lineHeight: '150%',
          fontWeight: 'bold',
        },
        sm: {
          fontSize: '14px',
          lineHeight: '150%',
          fontWeight: 'bold',
        },
      },
      body: {
        lg: {
          fontSize: '15px',
          lineHeight: '175%',
        },
        md: {
          fontSize: '13px',
          lineHeight: '175%',
        },
        sm: {
          fontSize: '11px',
          lineHeight: '150%',
        },
      },
      help: {
        fontSize: '12px',
        lineHeight: '150%',
      },
      jobTitle: {
        lg: {
          fontSize: '18px',
          lineHeight: '150%',
          fontWeight: 'bold',
        },
        md: {
          fontSize: '16px',
          lineHeight: '150%',
          fontWeight: 'bold',
        },
      },
      button: {
        fontSize: '16px',
        lineHeight: '150%',
        fontWeight: 'bold',
      },
    },
  },
})
